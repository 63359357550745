import React, { useEffect } from 'react'

import { isNumber } from 'lodash'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { InvoiceForm } from './InvoiceForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'

export const InvoiceEditPage = () => {
    const params = useParams()

    const navigate = useNavigate()

    useEffect(() => {
        if (isNaN(parseInt(params.invoiceId as string)) || parseInt(params.invoiceId as string) <= 0) {
            navigate('/app/invoices')
        }
    }, [params.invoiceId])

    return (
        <AppLayout>
            <AppTitle append={
                <Link to={'/app/invoices'}>
                    <button className="btn amani-primary">Späť</button>
                </Link>}>Faktúra #{params.invoiceId}</AppTitle>

            <InvoiceForm id={parseInt(params.invoiceId as string)}/>
        </AppLayout>
    )
}
import React from 'react'

import { ClientFormWrapper } from '../common/styledComponents'


const Commissions = () => {
  return (
    <ClientFormWrapper>
        <div>
            <strong>Prečo si Affiliate program zamiluješ?</strong>
            <br></br>
            Za každého nového zákazníka dostaneš skvelú odmenu.
            <br></br>
            Získavaš provízie aj z budúcich nákupov tvojich zákazníkov.
        </div>
            <div>
            <br></br>
            <strong>Provizie:</strong>
            <br></br>
            5% z každej mesačnej platby
            <br></br>
            <br></br>
            3% z každej ročnej platby - ak máš do 10 aktívnych predplatiteliek
            <br></br>
            5% z každej ročnej platby - ak máš do 20 aktívnych predplatiteliek
            <br></br>
            10% z každej ročnej platby - ak máš do 30 aktívnych predplatiteliek
            <br></br>
            15% z každej ročnej platby - ak máš do 40 aktívnych predplatiteliek
            <br></br>
            20% z každej ročnej platby - ak máš do 50 aktívnych predplatiteliek
            <br></br>
            25% z každej ročnej platby - ak máš do 60 aktívnych predplatiteliek
            <br></br>
            30% z každej ročnej platby - ak máš nad 61 aktívnych predplatiteliek
            <br></br>
        </div>
        <div>
            <br />
            <strong>Províziu dostávaš počas celej doby aktívneho predplatného používateľky.</strong>
            <br />
            <br />
            Min. výplata je 30eur, ktoré si môžeš vyplatiť alebo použiť na úhradu tvojho balíčka.
            <br/>Provízia za affiliate program bude vyplácaná na základe faktúry firmou The first million s. r. o. (IČO: 54 616 913)
        </div>
    </ClientFormWrapper>
    )
}

export default Commissions
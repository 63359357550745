import React, { PropsWithChildren } from 'react'

import TimeTrackingWidget from '../timesheet/TimeTrackingWidget'

export default function AppTitle({children, className = '', append = null}: PropsWithChildren<{
    className?: string,
    append?: React.ReactNode
}>) {


    return <>
        <div className={'pt-3 pb-2'}>
            <TimeTrackingWidget/>
        </div>
        <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mb-3">
            <h1 className={`h2 ${className}`}>{children}</h1>

            <div className="d-flex gap-4">
                {append}
            </div>
        </div>
    </>
}
import React, { useEffect } from 'react'

import toast from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { CalendarEventForm } from './CalendarEventForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'
import axiosClient from '../service/axiosClient'

export const CalendarEditPage = () => {
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (isNaN(parseInt(params.id as string)) || parseInt(params.id as string) <= 0) {
            navigate('/app/calendar')
        }
    }, [params.id])

    async function deleteEvent() {
        if (confirm('Naozaj si želáte danú udalosť vymazať?')) {
            await axiosClient.delete('calendar-event/' + params.id)
            toast.success('Udalosť bola úspešne vymazaná')
            navigate('/app/calendar')
        }
    }

    return (
        <AppLayout>
            <AppTitle append={
                <div><Link to={'/app/calendar'}>
                    <button className="btn amani-primary">Späť</button>
                </Link>
                    <button className="btn amani-primary ms-2" onClick={deleteEvent}>Vymazať</button>
                </div>}>Udalosť #{params.id}</AppTitle>

            <CalendarEventForm id={parseInt(params.id as string)}/>
        </AppLayout>
    )
}
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { animated, useSpring } from '@react-spring/web'
import classNames from 'classnames'

import { LoaderInBtn } from './LoaderInBtn'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading: boolean
}

export default function NiceButton({isLoading, children, className, ...props}: PropsWithChildren<ButtonProps>) {
    /* showLoader is used to stay in the "isLoading state" a bit longer to avoid loading flashes
     if the loading state is too short. */
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        if (isLoading) {
            setShowLoader(true)
        }

        // Show loader a bits longer to avoid loading flash
        if (!isLoading && showLoader) {
            const timeout = setTimeout(() => {
                setShowLoader(false)
            }, 400)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [isLoading, showLoader])

    /* Capture the dimensions of the button before the loading happens
    so it doesn’t change size.
    These hooks can be put in a seprate file. */
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const ref = useRef<HTMLButtonElement>(null)
    const [isInViewport, setIsInViewport] = useState(false)


    useEffect(() => {
        if (ref.current && !isInViewport) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setIsInViewport(true)
                        if (ref.current && ref.current.getBoundingClientRect().width) {
                            setWidth(ref.current.getBoundingClientRect().width)
                        }
                        if (ref.current && ref.current.getBoundingClientRect().height) {
                            setHeight(ref.current.getBoundingClientRect().height)
                        }
                    }
                }
            )
            observer.observe(ref.current)

            return () => {
                observer.disconnect()
            }
        }
    }, [isInViewport, ref.current])

    useEffect(() => {
        if (ref.current && ref.current.getBoundingClientRect().width) {
            setWidth(ref.current.getBoundingClientRect().width)
        }
        if (ref.current && ref.current.getBoundingClientRect().height) {
            setHeight(ref.current.getBoundingClientRect().height)
        }
    }, [children])

    // Hooks used to fade in/out the loader or the button contents
    const fadeOutProps = useSpring({opacity: showLoader ? 1 : 0})
    const fadeInProps = useSpring({opacity: showLoader ? 0 : 1})

    return (
        <button
            {...props}
            className={classNames('button btn btn-primary btn-amani', className, isLoading && 'isloading')}
            ref={ref}
            disabled={isLoading || !!props.disabled}
            style={
                showLoader
                    ? {
                        width: `${width}px`,
                        height: `${height}px`
                    }
                    : {}
            }
        >
            {showLoader ? (
                <animated.div style={fadeOutProps}>
                    <LoaderInBtn/>
                </animated.div>
            ) : (
                <animated.div style={fadeInProps}>{children}</animated.div>
            )}
        </button>
    )
}

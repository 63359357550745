import React, { useState } from 'react'

import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'

import { PostsListFilter } from './PostsListFilter'
import { ClientColorSquare } from '../common/ClientColorSquare'
import { SmartTable } from '../common/table/Table'
import { SmartTableContext } from '../common/table/TableContext'
import { SmartTableHeadCell } from '../common/table/TableHeadCell'
import { SmartTableWrapper } from '../common/table/TableWrapper'
import axiosClient from '../service/axiosClient'
import { PageableResponse, PagingRequest } from '../types/Common'
import PostDTO, { PostsFilter } from '../types/Post'
import SocialNetwork from '../types/SocialNetwork'

export const PostsList = () => {
    const navigate = useNavigate()

    const [filterInternal, setFilterInternal] = useState<PostsFilter>({
        clientId: '',
    })

    function loadDataFn(queryParams: PagingRequest) {
        console.log('queryParams', queryParams)
        const pageNumber = queryParams.pageStart || 0
        const pageSize = queryParams.pageSize || 15
        return new Promise<PageableResponse<PostDTO>>(
            (resolve) => {
                axiosClient.get('post', {
                    params: {
                        pageSize: pageSize,
                        pageStart: pageNumber,
                        clientId: filterInternal.clientId,
                    }
                }).then(response => {
                    const totalElements = response.data.totalElements
                    const paginatedData = response.data.content
                    return resolve({
                        hasNextPage: true,
                        totalElements: totalElements,
                        content: paginatedData,
                    })
                }).catch(error => {
                    console.log('error', error)
                })
            }
        )
    }

    async function deletePost(id: number, reload: () => void) {
        if (confirm('Naozaj si želáte daný príspevok vymazať?')) {
            await axiosClient.delete('post/' + id)
            toast.success('Príspevok bol úspešne vymazaný')
            reload()
        }
    }

    return <>
        <PostsListFilter filter={filterInternal} setFilter={setFilterInternal}/>
        {/*<div className="rounded-content-body mb-4">*/}
        <SmartTableWrapper<PostDTO, PostsFilter>
            initialPageSize={10}
            filter={filterInternal}
            loadDataFn={loadDataFn}>
            <SmartTableContext.Consumer>
                {api => (
                    <SmartTable<PostDTO>
                        onRowClick={(item) => {
                            // navigate('/posts/edit/' + item.id)
                        }}
                        HeadRow={
                            <>
                                {/*<SmartTableHeadCell>*/}
                                {/*    #*/}
                                {/*</SmartTableHeadCell>*/}
                                <SmartTableHeadCell>
                                    Klient
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Titulok
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Popis
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Typ
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Sieť
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Dátum
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Čas
                                </SmartTableHeadCell>
                                <SmartTableHeadCell
                                    className="text-end">
                                    Akcia
                                </SmartTableHeadCell>
                            </>
                        }
                        renderRow={(item) => {
                            return (
                                <>
                                    {/*<td>{item.id}</td>*/}
                                    <td><ClientColorSquare
                                        clientId={item.clientId}/>{`${item.clientFirstName} ${item.clientLastName}`}
                                    </td>
                                    <td>{item.title}</td>
                                    <td>{item.description}</td>
                                    <td className={'text-capitalize'}>{item.postType ?? '-'}</td>
                                    <td><i
                                        className={`bi bi-${item.socialNetwork === SocialNetwork.FACEBOOK ? 'facebook' : item.socialNetwork === SocialNetwork.INSTAGRAM ? 'instagram' : 'linkedin'}`}></i>
                                    </td>
                                    <td>{new Date(item.publishFrom).toLocaleDateString('sk-SK')} {item.publishUntil ? ' - ' + new Date(item.publishUntil).toLocaleDateString('sk-SK') : null}</td>
                                    <td>{item.publishAt.slice(0, 5)}</td>
                                    <td className="text-right">
                                        <div className="d-flex gap-2 justify-content-end">
                                            <Link className={'table-action-link'} to={'/app/posts/edit/' + item.id} title={'Upraviť'}>
                                                <i className="bi bi-pencil primary-text-color"></i>
                                                {/*<button className="btn amani-primary amani-small">Upraviť</button>*/}
                                            </Link>
                                            <a href={'#'} className={'table-action-link'} onClick={(e) => {
                                                e.preventDefault()
                                                item.id && deletePost(item.id, () => api.load())
                                            }} title={'Vymazať'}>
                                                <i className="bi bi-trash primary-text-color"></i>
                                                {/*<button className="btn amani-primary amani-small">Upraviť</button>*/}
                                            </a>
                                        </div>
                                    </td>

                                </>
                            )
                        }}
                    />)}</SmartTableContext.Consumer>

        </SmartTableWrapper>
        {/*</div>*/}
    </>
}
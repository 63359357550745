import React, { useState } from 'react'

import 'react-multi-carousel/lib/styles.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { PriceTable } from './PriceTable'
import obr4 from '../assets/11.png'
import amaniCoffe from '../assets/amani-coffee.png'
// import amaniCoffe from '../assets/amani-coffee.svg'
import client1 from '../assets/client1.png'
import client10 from '../assets/client10.png'
import client2 from '../assets/client2.png'
import client3 from '../assets/client3.png'
import client4 from '../assets/client4.png'
import client5 from '../assets/client5.png'
import client6 from '../assets/client6.png'
import client7 from '../assets/client7.png'
import client8 from '../assets/client8.png'
import client9 from '../assets/client9.png'
import fotka from '../assets/fotka_okruhla.png'
import l3 from '../assets/L3.png'
import lt_yellow from '../assets/lt_yellow.svg'
import slide4 from '../assets/slide-kava.png'
import slide1 from '../assets/slide1.png'
import slide2 from '../assets/slide2.png'
import slide3 from '../assets/slide3.png'
import PublicLayout from '../common/PublicLayout'
import axiosClient from '../service/axiosClient'
import { Package, PaymentPeriod } from '../types/gopay'

function HomePage() {
    const [isSubscribeBtnDisabled, setIsSubscribeBtnDisabled] = useState(false)
    return (
        <PublicLayout>
            {/*<FullheightWrapper>*/}
            <div className="d-flex min-h-100v-header align-items-center">
                <Container fluid>
                    <Row>
                        <Col md>
                            {/*<div className="flex-basis-100">*/}
                            <img className={'w-100'} src={slide1} style={{paddingTop: '55px'}}/>
                            {/*</div>*/}
                        </Col>
                        <Col md>
                            <div className=" pe-5 text-center d-flex justify-content-center gap-4 flex-column h-100">
                                <h2>
                                    <span
                                        className="font-vollkorn fs-55">Manažujte svoje online aplikácie</span><br/><span
                                    className={'font-shantell color-pink-primary d-block'} style={{padding: '25px'}}>z jedného miesta</span>
                                </h2>
                                <h3>
                                    Už žiadne blúdenie medzi oknami, <br/>len čistá efektivita a pohoda!
                                </h3>
                                <div className="text-center">
                                    <Link to={`/register?package=${Package.FREE}&period=${PaymentPeriod.MONTHLY}`}>
                                        <button
                                            className="btn btn-primary pink-color btn-amani w-auto d-inline-block text-uppercase">Vyskúšaj
                                            zadarmo
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="d-flex min-h-100vh align-items-center dots-bg-left-top">
                <Container fluid>
                    <Row>
                        <Col md={2}/>
                        <Col md={4}>
                            <div className=" ps-5 text-center d-flex justify-content-center gap-4 flex-column h-100">
                                <h2 className={'font-vollkorn fs-40'}>
                                    Vitaj vo svojej<br/>
                                    online kancelárii
                                </h2>
                                <p className={'color-primary'}>
                                    <strong className={'font-shantell color-pink-primary'}>mieste, kde môžeš s prehľadom
                                        skĺbiť prácu s rodinou.</strong>
                                    <br/><br/>
                                    Budem k tebe úprimná!
                                    <br/><br/>
                                    Práca a rodina sa nedá <i className={'font-shantell'}>oddeliť</i>.
                                    <br/><br/>
                                    A my ženy, to ani meniť nechceme.
                                    <br/><br/>
                                    Môžeme ju iba spoločne ladiť a prispôsobovať.
                                    <br/><br/>
                                    Amani ti s tým s ľahkosťou pomôže.
                                </p>
                                <div className="text-center">
                                    <Link to={'/register'}>
                                        <button
                                            className="btn btn-primary pink-color btn-amani w-auto d-inline-block text-uppercase">CHCEM
                                            SA ZAREGISTROVAŤ
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            {/*<div className="flex-basis-100">*/}
                            <img className={'w-100'} src={slide2}/>
                            {/*</div>*/}
                        </Col>
                        {/*<Col md={1} />*/}
                    </Row>
                </Container>
            </div>


            <div className="d-flex min-hS-100vh align-items-center bg-body dots-bg-right-bottom">
                <Container className={'min-hS-100vh'}>
                    <Row>
                        <Col md>
                            {/*<div className="flex-basis-100">*/}
                            <img className={'w-100'} src={slide4}/>
                            {/*</div>*/}
                        </Col>
                        <Col md>
                            <div className={'dark-title-wrapper-with-avatar'}>
                                <div>
                                    <span>Možno rozmýšľaš...</span>
                                    <h4 className={'font-shantell fs-40'}>Potrebujem takúto kanceláriu?</h4>
                                </div>
                            </div>
                            <p className={'color-primary'}>Amani sa stane tvojou najlepšou kolegyňou, ak:</p>
                            <ul className={'color-primary line-height-2'}>
                                <li>si virtuálna asistentka</li>
                                <li>si freelancerka</li>
                                <li>rozbiehaš svoje podnikanie a potrebuješ zladiť množstvo procesov</li>
                                <li>tvoje podnikanie už nabralo na obrátkach a potrebuješ <br/>automatizovať a
                                    sprehľadniť
                                    procesy
                                </li>
                            </ul>

                            <div className="ps-4 pt-4">
                                <Link to={'/register'}>
                                    <button
                                        className="btn btn-primary pink-color btn-amani w-auto d-inline-block text-uppercase">
                                        POTREBUJEM TÚTO KANCELÁRIU
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="d-flex min-hS-100vh align-items-center bg-dark-gray py-5">
                <Container>
                    <Row>
                        <h4 className="primary-text-color text-bold-400">Už žiadne prepínanie medzi aplikáciami, žiadne
                            zabudnuté heslá</h4>
                        <h5 className="font-vollkorn fs-40 text-uppercase text-center pt-4 primary-text-color mb-5">Všetko
                            na
                            jednom mieste</h5>
                    </Row>
                    <Row className={''}>
                        <Col className="d-flex flex-column align-items-center">
                            <div className="features-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-instagram" viewBox="0 0 16 16">
                                    <path
                                        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                </svg>
                            </div>
                            <h4 className="text-uppercase text-white">sociálne siete</h4>
                        </Col>
                        <Col className="d-flex flex-column align-items-center">
                            <div className="features-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-calendar4-week" viewBox="0 0 16 16">
                                    <path
                                        d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                    <path
                                        d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                            </div>
                            <h4 className="text-uppercase text-white">kalendár
                                &
                                Plánovanie</h4>
                        </Col>
                        <Col className="d-flex flex-column align-items-center">
                            <div className="features-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-list-ol" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5"/>
                                    <path
                                        d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635z"/>
                                </svg>
                            </div>

                            <h4 className="text-uppercase text-white">fakturácia</h4></Col>
                        <Col className="d-flex flex-column align-items-center">
                            <div className="features-circle">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-envelope-at" viewBox="0 0 16 16">
                                    <path
                                        d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
                                    <path
                                        d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                                </svg>
                            </div>
                            <h4 className="text-uppercase text-white">Administratíva
                                &
                                emailing</h4>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="feature-slide primary-bg-color pt-5 pb-5">
                <Container>
                    <Row>
                        <Col md>
                            <h4 className={'text-uppercase secondary-pink-text-color'}>Sociálne siete</h4>
                            <ul>
                                <li>manažment sociálnych sietí</li>
                                <li>plánovanie príspevkov na sociálne siete</li>
                                <li>zdielanie publikačných plánov s klientom</li>
                                <li>priame publikovanie príspevkov</li>
                            </ul>
                        </Col>
                        <Col md>
                            <h4 className={'text-uppercase secondary-pink-text-color'}>Kalendár a plánovanie</h4>
                            <ul>
                                <li>spravovanie kalendára</li>
                                <li>pripomienkovač</li>
                                <li>spravovanie úloh</li>
                                <li>synchronizácia s Google kalendárom</li>
                            </ul>
                        </Col>
                        <Col md>
                            <h4 className={'text-uppercase secondary-pink-text-color'}>Fakturácia</h4>
                            <ul>
                                <li>trackovanie času</li>
                                <li>vystavovanie faktúr</li>
                                <li>editácia faktúr</li>
                                <li>export xml súborov pre účtovníctvo</li>
                                <li>automatické odosielanie faktúr emailom klientovi</li>
                            </ul>
                        </Col>
                        <Col md>
                            <h4 className={'text-uppercase secondary-pink-text-color'}>Administratíva</h4>
                            <ul>
                                <li>karta klienta</li>
                                <li>poznámky</li>
                                <li>ToDo list</li>
                                <li>úložný priestor</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="d-flex min-h-100Xvh bg-white align-items-center flex-wrap justify-content-center">
                {/*<Container fluid>*/}
                {/*    <Row>*/}
                {/*        <Col md className={'d-flex'}>*/}
                <div className=" ps-5 text-center d-flex justify-content-center  gap-4 flex-column h-100">
                    <h2 className={'fs-40 font-shantell color-pink-primary'}>
                        Priprav sa na...
                    </h2>
                    <div className={'pl-50'}>
                        <ul className="homepage-li color-primary">
                            <li> jednoduchú navigáciu</li>
                            <li>všetko v slovenčine</li>
                            <li>bez zložitých manuálov</li>
                            <li>affiliate program</li>
                            <li>zladenie rodiny a práce</li>
                        </ul>
                    </div>

                    <div className="text-center">
                        <Link to={'/register'}>
                            <button
                                className="btn btn-primary pink-color btn-amani w-auto d-inline-block text-uppercase">To
                                chcem určite vyskúšať
                            </button>
                        </Link>
                    </div>

                </div>
                {/*</Col>*/}
                {/*<Col md>*/}
                {/*<div className="flex-basis-100">*/}
                <img width="400" src={slide3} className={'mb-5 ms-5 mt-5'}/>
                {/*</div>*/}
                {/*</Col>*/}
                {/*</Row>*/}
                {/*</Container>*/}
            </div>

            <div id={'cennik'}
                 className="d-flex min-h-100vh dots-bg-right-bottom bg-body align-items-center justify-content-center scroll-margin">

                <PriceTable />

            </div>

            <div id={'affiliate'}
                 className="d-flex min-h-100Xvh bg-white align-items-center justify-content-center flex-column py-5 scroll-margin">
                <h3 className="fs-40 font-vollkorn mb-3">Pripoj sa k Affiliate programu Amani</h3>
                <div className={'d-flex justify-content-center mb-5'}>
                    <p className="text-center max-width-800 color-primary">
                        Je to náš partnerský program, vďaka ktorému si môžeš prilepšiť. Predstav si, že odporúčaš niečo
                        super svojej kamarátke a ešte za to dostaneš aj odmenu. Je to jednoduché!
                    </p>
                </div>

                <Container className={'min-hS-100vh'}>
                    <Row>
                        <div className="d-flex flex-column flex-lg-row mt-5 mt-md-0 ">
                            {/*<Col md={5}>*/}
                            <div className="affiliate-gray-avatar">
                                <div className="avatar"></div>
                                <h5 className="font-shantell color-pink-primary pb-3">Prečo si Affiliate program
                                    zamiluješ?</h5>
                                <ul className="homepage-li pl-20 color-primary">
                                    <li className="text-transform-none">Za každého nového zákazníka dostaneš skvelú
                                        odmenu.
                                    </li>
                                    <li className="text-transform-none">Získavaš provízie aj z budúcich nákupov tvojich
                                        zákazníkov.
                                    </li>
                                    <li className="text-transform-none">Všetko máš prehľadne v našom systéme.</li>
                                </ul>
                            </div>
                            {/*</Col>*/}
                            {/*<Col md={7}>*/}
                            <div className="ps-4 py-3 ltlbgray2bg">
                                <div className="fs-21 pb-3 color-primary">Ako to funguje?</div>
                                <ol type="1" className={'color-primary'}>
                                    <li><strong>Zaregistruješ sa</strong> do nášho affiliate programu.</li>
                                    <li>Dostaneš <strong>unikátny odkaz</strong>, ktorý môžeš zdieľať na svojom blogu,
                                        sociálnych sieťach
                                        alebo e-mailových kampaniach.
                                    </li>
                                    <li><strong>Získavaš províziu</strong> za každú novú podnikateľku, ktorá sa
                                        zaregistruje a nakúpi cez
                                        tvoj affiliate link.
                                    </li>
                                    <li>Provízia za affiliate program bude vyplácaná na základe faktúry firmou The first million s. r. o. (IČO: 54 616 913).
                                    </li>
                                </ol>
                                <button
                                    className="btn btn-primary pink-color btn-amani w-auto d-inline-block text-uppercase">chcem
                                    odporúčať a zarábať
                                </button>
                            </div>

                            {/*</Col>*/}
                        </div>

                    </Row>
                </Container>
            </div>


            <div
                className="d-flex min-h-100svh  bg-white align-items-center justify-content-center flex-column text-center dots-bg-left-bottom-pink3">
                <div className="hunady-pink-bg">
                    <img src={fotka} alt="fotka"/>
                </div>
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8}>
                            <h4 className="font-vollkorn fs-40 pb-2">Som Katka</h4>
                            <h5 className="text-uppercase font-vollkorn fs-15 ls-5 color-primary pb-2">organizovaná,
                                efektívna A cieľavedomá</h5>
                            <h5 className="font-shantell fs-21 color-pink-primary pb-4">a Amani je mojím druhým
                                dieťaťom...</h5>
                            <div className={'d-flex justify-content-center'}>
                                <p className="text-center max-width-570 color-primary">
                                    Som jedna z vás.<br/>
                                    Som virtuálna asistentka a špecializujem sa na projektový manažment.
                                    Ako mama malej Hanky viem, aké náročné je skĺbiť prácu a rodinu. Čas je pre mňa
                                    to
                                    najvzácnejšie, a preto som hľadala spôsoby, ako si uľahčiť prácu, ktorú milujem
                                    a
                                    zároveň sa
                                    venovať tomu, čo je pre mňa naozaj dôležité.
                                    Mojou misiou je podporiť podnikateľky v hľadaní rovnováhy medzi prácou a rodinou
                                    a
                                    ukázať,
                                    že sa to dá zvládnuť.
                                </p>
                            </div>

                            <p className="font-shantell pt-3 color-primary">
                                A tak vznikol nápad uľahčiť prácu sebe, ale hlavne tebe.
                            </p>
                            <img src={l3} alt="" style={{'width': '150px'}}/>
                        </Col>
                        <Col md={2}>
                            <div className="d-flex h-100">

                                <img src={obr4} style={{
                                    'width': '150%',
                                    marginTop: 'auto',
                                    marginBottom: '50px',
                                    marginLeft: '-50%'
                                }}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="d-flex min-hS-100vh align-items-center bg-dark-gray2 py-5 dots-bg-right-top-gray">
                <Container>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={10}>
                            <h4 className="font-vollkorn fs-40 text-left">Spolupracujem...</h4>
                        </Col>
                    </Row>
                    <Row>
                        <div className="d-flex flex-row flex-wrap clients justify-content-around">
                            <a href="https://www.doody.sk/" target={'_blank'} rel="noreferrer"><img src={client1}
                                                                                                    alt=""/></a>

                            <a href="https://expertklub.com/" target={'_blank'} rel="noreferrer"><img src={client3}
                                                                                                      alt=""/></a>
                            <a href="https://www.klubdzezva.sk/" target={'_blank'} rel="noreferrer"><img src={client2}
                                                                                                         alt=""/></a>
                            <a href="https://dynastic.sk/" target={'_blank'} rel="noreferrer"><img
                                src={client7} alt=""/></a>
                            <a href="https://www.patrikbenik.sk/" target={'_blank'} rel="noreferrer"><img
                                src={client4} alt=""/></a>
                            <a href="https://www.rifin.sk/" target={'_blank'} rel="noreferrer"><img
                                src={client5} alt=""/></a>
                            <a href="https://havranova.sk/" target={'_blank'} rel="noreferrer"><img
                                src={client6} alt=""/></a>
                            <a href="https://www.ennacca.com/" target={'_blank'} rel="noreferrer"><img
                                src={client8} alt=""/></a>
                            <a href="https://www.fitfamilyradio.com/" target={'_blank'} rel="noreferrer"><img
                                src={client9} alt=""/></a>
                            <a href="https://cdu.sk/" target={'_blank'} rel="noreferrer"><img
                                src={client10} alt=""/></a>
                        </div>
                    </Row>
                    <Row>
                        <Col md={10}>
                            <h4 className="font-shantell fs-40 text-right">...a môžeš aj ty</h4>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </Container>
            </div>

            <div className="d-flex min-hS-100vh align-items-center bg-primary-yellow position-relative">
                <img src={lt_yellow} className={'lt-yellow'}/>
                <Container style={{zIndex: 2}}>
                    <Row>
                        <Col md={4} className={'p-0'}>
                            <img src={amaniCoffe} className="w100p"/>
                        </Col>
                        <Col md={7} style={{marginLeft: '-5%'}}>
                            <h4 className={'font-shantell color-pink-primary fs-40 mt-5 text-left mb-5'}>Info pri
                                kávičke...</h4>
                            <p className={'text-center mb-5 color-primary'}>
                                Mám v pláne ti pravidelne prinášať nálož inšpirácie, praktických rád<br/> a trochu
                                zábavy,
                                aby si mohla vo svojej práci ešte viac vynikať.
                                <br/>Stačí jeden klik nižšie.
                            </p>
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                // @ts-ignore
                                const email = e?.target[0]?.value || ''
                                if (email) {
                                    setIsSubscribeBtnDisabled(true)
                                    axiosClient.post('/newsletter/subscribe', {
                                        email
                                    })
                                }
                            }} className={'form-group d-flex flex-row  pe-5 gap-3'}
                                  style={{'width': '90%', 'marginLeft': '10%'}}>
                                {!isSubscribeBtnDisabled ? <> <input className={'form-control'} name={'eml'}
                                                                     type="email" placeholder={'Tvoj email'}
                                                                     required/>
                                    <button
                                        disabled={isSubscribeBtnDisabled}
                                        className={'btn btn-primary pink-color btn-amani w-auto d-inline-block text-uppercase'}>odber
                                        newslettera
                                    </button>
                                </> : <> <p className={' text-center color-primary'}>
                                    Ďakujeme, boli ste úspešne zaregistrovaný na odber newsletra.
                                </p></>}

                            </form>
                            <p className={' text-center mt-5 color-primary'}>
                                Sľubujeme, že tvoj inbox nebude nikdy preplnený a zahltený nudným spamom!<br/>
                                Teším sa na teba. 💌
                            </p>
                        </Col>
                        <Col md={1}/>
                    </Row>
                </Container>
                {/*<img src={rb_yellow} className={'rb-yellow'}/>*/}
            </div>


            {/*</FullheightWrapper>*/}
            {/*<div className="text-center d-flex flex-column align-items-center gap22">*/}
            {/*    <span className="cc1">Vstúp do platformy Amani</span>*/}

            {/*    <h1>Tuto je nadpis<br/>*/}
            {/*        aj dvojriadkovy</h1>*/}
            {/*    <span className="cc2">Zaregistrovať sa je jednoduché a rýchle. Zaberie to len pár minút.</span>*/}
            {/*</div>*/}
        </PublicLayout>
    )
}

export default HomePage

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import moment from 'moment'
import 'moment/locale/sk'
import { Calendar, EventProps, momentLocalizer, Views } from 'react-big-calendar'
import { Link, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'
import { fetchClients } from '../common/helpers'
import axiosClient from '../service/axiosClient'
import { CalendarComponentEvent, CalendarEvent, CalendarEventType } from '../types/Calendar'
import ClientDTO from '../types/Client'
import { PageableResponse } from '../types/Common'


const localizer = momentLocalizer(moment)

function EventComponent({event}: EventProps<CalendarComponentEvent>) {
    console.log('eventevent', event)
    return <div style={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        backgroundColor: event.color ? event.color : '#3A3E5C',
        paddingLeft: '2px',
        paddingRight: '2px',
        borderRadius: '2px'
    }}>{event.title}&nbsp;</div>
}

const CustomToolbar = (props: any) => {
    // Získanie lokalizovaných textov
    const {messages} = props.localizer

    const goToBack = () => {
        props.onNavigate('PREV')
    }

    const goToNext = () => {
        props.onNavigate('NEXT')
    }

    const goToToday = () => {
        props.onNavigate('TODAY')
    }

    function view(view: any) {
        props.onView(view)
    }

    function viewNamesGroup(messages: any) {
        const viewNames = props.views
        // const view = props.view

        if (viewNames.length > 1) {
            return viewNames.map((name: any) => (
                <button
                    type="button"
                    key={name}
                    className={classNames('btn amani-primary pink-btn small', {'rbc-active': view === name})}
                    onClick={
                        () => view(name)}
                >
                    {messages[name]}
                </button>
            ))
        }
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
            position: 'relative'
        }}>
            <button onClick={goToToday} className={'btn amani-primary pink-btn small'}>{messages.today}</button>
            {/*<button onClick={goToBack}>{messages.previous}</button>*/}
            <button type="button" onClick={goToBack} style={{position: 'initial'}}
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                    aria-label={messages.previous}><span
                className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">{messages.previous}</span>
            </button>

            <span className={'rbc-toolbar-label'} style={{margin: 0}}>{props.label}</span> {/* Lokalizovaný názov mesiaca/roku */}
            {/*<button onClick={goToNext}>{messages.next}</button>*/}
            <button type="button" className="react-datepicker__navigation react-datepicker__navigation--next"
                    style={{position: 'initial'}}
                    aria-label={messages.next} onClick={goToNext}><span
                className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">{messages.next}</span>
            </button>

            <span className="rbc-btn-group">{viewNamesGroup(messages)}</span>

        </div>
    )
}

function CalendarPage() {
    const navigate = useNavigate()
    const [eventTypes, setEventTypes] = useState<CalendarEventType[]>([])
    const [myEvents, setEvents] = useState<CalendarComponentEvent[]>([])
    const [clients, setClients] = useState<ClientDTO[]>([])
    const [selectedClient, setSelectedClient] = useLocalStorage<ClientDTO | null>('calendar-client', null)
    const [selectedType, setSelectedType] = useLocalStorage<CalendarEventType | null>('calendar-event-type', null)

    useEffect(() => {
        axiosClient.get('calendar-event/type').then(response => {
            console.log('response types', response)
            setEventTypes(response.data)
        }).catch(error => {
            console.log('error', error)
        })
        fetchClients().then(setClients).catch(console.error)
    }, [])

    useEffect(() => {
        // if (selectedClient && selectedClient?.id) {
        axiosClient.get<PageableResponse<CalendarEvent>>('calendar-event', {
            params: {
                clientId: selectedClient?.id,
                pageSize: 200,
                pageStart: 0
            }
        })
            .then(response => {
                console.log('res', response)
                const eventsTmp: CalendarComponentEvent[] = []
                response.data.content.forEach(item => {
                    const start = new Date(item.entryDate + 'T' + item.startedAt)
                    const end = new Date(item.entryDate + 'T' + item.endedAt)

                    if ((selectedType && selectedType.id === item.eventType.id) || !selectedType) {
                        eventsTmp.push({
                            id: item.id as number,
                            title: item.name,
                            start: start,
                            end: end,
                            color: item.color,
                            // allDay: true
                        })
                    }
                })
                console.log('eventsTmp', eventsTmp)
                setEvents(eventsTmp)
            }).catch(error => {
            console.log('error', error)
        })
        // }
    }, [selectedClient, selectedType])

    const handleSelectSlot = useCallback(
        ({start, end}: { start: Date, end: Date }) => {
            console.log('{start, end}', {start, end})
            // navigate(`/calendar/add?clientId=${selectedClient?.id}&start=${start.toDateString()}&end=${end.toDateString()}`)
            navigate(`/app/calendar/add?clientId=${selectedClient?.id}&entryDate=${start.getFullYear() + '-' + ('0' + (start.getMonth() + 1)).slice(-2) + '-' + ('0' + start.getDate()).slice(-2)}`)
            // const title = window.prompt('New Event Name')
            // if (title) {
            //     //@ts-ignore
            //     setEvents((prev) => [...prev, {start, end, title}])
            // }
        },
        [selectedClient, selectedType]
    )

    const handleSelectEvent = useCallback((event: CalendarComponentEvent) => {
        navigate('/app/calendar/edit/' + event.id)
    }, [])

    const {defaultDate, scrollToTime} = useMemo(
        () => ({
            defaultDate: new Date(),
            scrollToTime: new Date(1970, 1, 1, 6),
        }),
        []
    )


    return (
        <AppLayout>

            <AppTitle className={'d-flex'} append={<Link to={`/app/calendar/add?clientId=${selectedClient?.id}`}>
                <button className="btn amani-primary">Nová udalosť</button>
            </Link>}>
                Kalendár
            </AppTitle>

            <div className="rounded-content-body mb-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4">
                        <div
                            className={'form-group'}>
                            <label htmlFor="clientId">
                                Klient
                            </label>
                            <select
                                id="clientId"
                                name="clientId"
                                className="form-select"
                                onChange={(e) => {
                                    setSelectedClient(clients.find(client => client.id === parseInt(e.target.value)) || null)
                                    // setFilter((old) => ({...old, clientId: e.target.value}))
                                }}>
                                <option value="">Vyberte</option>
                                {clients.map((option) => (
                                    <option key={option.id}
                                            selected={selectedClient?.id === option.id}
                                            value={option.id}>{option.companyName || (`${option.firstName} ${option.lastName}`)}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div
                            className={'form-group'}>
                            <label htmlFor="eventType">
                                Typ udalosti
                            </label>
                            <select id="eventType" name="eventType"
                                    value={selectedType?.id}
                                    onChange={(e) => {
                                        const eventType = eventTypes.find(et => et.id.toString() === e.target.value)
                                        console.log('eventType', eventType)
                                        setSelectedType(eventType || null)
                                    }}
                                    className="form-select">
                                <option value="">Vyberte</option>
                                {eventTypes.map((option) => (
                                    <option key={option.id}
                                            value={option.id}>{option.name}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                    </div>

                </div>
            </div>

            <div className="rounded-content-body p-4">
                <Calendar
                    defaultDate={defaultDate}
                    defaultView={Views.MONTH}
                    events={myEvents}
                    localizer={localizer}
                    onSelectEvent={handleSelectEvent}
                    onSelectSlot={handleSelectSlot}
                    selectable
                    components={{
                        event: EventComponent,
                        toolbar: CustomToolbar,
                    }}
                    scrollToTime={scrollToTime}
                    messages={{
                        next: 'Ďalší',
                        previous: 'Predchádzajúci',
                        today: 'Dnes',
                        month: 'Mesiac',
                        week: 'Týždeň',
                        day: 'Deň',
                        time: 'Čas',
                        event: 'Príspevok',
                        allDay: 'Celý deň',
                        showMore: (count) => 'Zobraziť viac',
                        date: 'Dátum',
                    }}
                />
            </div>
        </AppLayout>
    )
}

export default CalendarPage

import React, { useState } from 'react'

import { Tab, Tabs } from 'react-bootstrap'

import AffiliateCommisionLetters from './AffiliateCommisionLetters'
import AffiliateCommissions from './AffiliateCommissions'
import AffiliateLink from './AffiliateLink'
import AffiliatePayout from './AffiliatePayout'
import AffiliatePromo from './AffiliatePromo'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'


const AffiliatePage = () => {
    const [activeKey, setActiveKey] = useState('provizie')  // Set active key to 'provizie' on first render
    const [commissionLettersFilter, setCommissionLettersFilter] = useState<'all' | 'resolved' | 'unresolved'>('all') 
    const [refetchKey, setRefetchKey] = useState(0) // State variable to trigger re-fetch

    const triggerRefetch = () => {
        setRefetchKey((prevKey) => prevKey + 1)
    }
    return (
        <AppLayout>
            <AppTitle>Affiliate program</AppTitle>
                <Tabs                                       // ! Theres a difference between 'provízie' and 'provízne'... I should have used better names :D
                activeKey={activeKey}
                onSelect={(activeKey) => {                  
                    setActiveKey(activeKey || 'provizie')   // Set active key to the selected key or 'provizie' if the selected key is null
                    if(activeKey === 'provizne') {          // If the user clicks on the 'Provízne listy' tab 
                        setCommissionLettersFilter('all')   // Reset filter
                    }
                }}
                id="affiliate-tabs"
                >
                    <Tab eventKey="provizie" title="Provízie" > {/* default static tab */}
                        <AffiliateCommissions></AffiliateCommissions>
                    </Tab>
                    <Tab eventKey="odkazy" title="Odkazy" >     {/* special link */}
                        <AffiliateLink></AffiliateLink>
                    </Tab>
                    <Tab eventKey="vyplata" title="Výplata" >   {/* payout tab to show credit, resolved and unresolved commision letters. */}
                        <AffiliatePayout 
                        setActiveKey={setActiveKey}   
                        setCommissionLettersFilter={setCommissionLettersFilter}
                        triggerRefetch={triggerRefetch}
                        /> 
                    </Tab>
                    <Tab eventKey="provizne" title="Provízne listy" >   {/* commission letters tab */}
                        <AffiliateCommisionLetters 
                        key={refetchKey}
                        filter={commissionLettersFilter}/> 
                    </Tab>
                    <Tab eventKey="promo" title="Promo materíaly" >     {/* promo materials tab */}
                        <AffiliatePromo></AffiliatePromo>
                    </Tab>
                </Tabs>
        </AppLayout>
    )
}

export default AffiliatePage

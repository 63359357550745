import React from 'react'

import toast from 'react-hot-toast'

import { money } from '../common/helpers'
import { ClientFormWrapper } from '../common/styledComponents'
import { SmartTable } from '../common/table/Table'
import { SmartTableContext } from '../common/table/TableContext'
import { SmartTableHeadCell } from '../common/table/TableHeadCell'
import { SmartTableWrapper } from '../common/table/TableWrapper'
import axiosClient from '../service/axiosClient'
import { PageableResponse, PagingRequest } from '../types/Common'
import { AffiliateCommisionLettersProps, AffiliatePayoutProps } from '../types/Payout'


const API_PATHS = {
    commisionLetterAll: `${process.env.REACT_APP_SERVER_URL}/affiliate/payout-list/all`,                // Fetch all commision letters
    commisionLetterResolved: `${process.env.REACT_APP_SERVER_URL}/affiliate/payout-list/resolved`,      // Fetch resolved commision letters
    commisionLetterUnresolved: `${process.env.REACT_APP_SERVER_URL}/affiliate/payout-list/unresolved`,  // Fetch unresolved commision letters
    commisionLetterdownload: `${process.env.REACT_APP_SERVER_URL}/affiliate/download/`,                 // Download commision letter
}

/**
 * Component for rendering affiliate commission letters
 * @param filter - Filter for resolved or unresolved commision letters
 */
const AffiliateCommisionLetters: React.FC<AffiliateCommisionLettersProps> = ({ filter }) => {
    // React.FC => FC = FunctionComponent
    // Usefull for TypeScript, enebles type checking with props (if i understand correctly)

    /**
     * Function for fetching data for SmartTable
     * @param queryParams - PagingRequest
     * @returns Promise<PageableResponse<AffiliatePayoutProps>>
     * 
     * @example 
     * loadDataFn({pageStart: 0, pageSize: 15})
     * .then((response) => {
     *     console.log('Data:', response.content)
     * })
     * .catch((error) => {
     *    console.error('Error fetching data:', error)
     * })
     * 
     */
    const loadDataFn = (queryParams: PagingRequest) => {
        const pageNumber = queryParams.pageStart || 0
        const pageSize = queryParams.pageSize || 15
    
        return new Promise<PageableResponse<AffiliatePayoutProps>>((resolve) => {
            const activePath = filter === 'all' ?                                                       // If filter is 'all' it will fetch all commision letters
                                API_PATHS.commisionLetterAll : filter === 'resolved' ?                  // If filter is 'resolved' it will fetch resolved commision letters
                                API_PATHS.commisionLetterResolved : API_PATHS.commisionLetterUnresolved // If filter is 'unresolved' it will fetch unresolved commision letters
    
            axiosClient.get(activePath, {
                    params: {
                        pageSize: pageSize,
                        pageStart: pageNumber,
                    },
                })
                .then((response) => {
                    const content = response.data as AffiliatePayoutProps[]
                    content.reverse() // Reverse array to show the latest commision letters first
    
                    resolve({
                        hasNextPage: content.length > pageSize,
                        totalElements: content.length,
                        content: content.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize),
                    })
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                    toast.error('Chyba pri načítaní dát')
                    resolve({
                        hasNextPage: false,
                        totalElements: 0,
                        content: [],
                    })
                })
        })
    }

    // Copy-paste from InvoiceForm.tsx
    async function downloadCommisionLetter(commissionLetterNumber: string) {
        try {
            const response = await axiosClient({
                url: `${API_PATHS.commisionLetterdownload}${commissionLetterNumber}`,
                method: 'GET',
                responseType: 'blob', // important 
            })
    
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data)
    
            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', `P${commissionLetterNumber}.pdf`) // or any other extension
            document.body.appendChild(link)
            link.click()
    
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
    
            toast.success('Faktúra bola úspešne stiahnutá')
        } catch (error) {
            console.error('Error downloading commision letter commissionLetterNumber=' + commissionLetterNumber + ':', error)
            toast.error('Chyba pri sťahovaní faktúry')
        }
    }




    return (
        <ClientFormWrapper>
            <SmartTableWrapper<AffiliatePayoutProps, any>
                key={filter}
                initialPageSize={10}
                loadDataFn={loadDataFn}
            >
                <SmartTableContext.Consumer>
                    {(api) => (
                        <SmartTable<AffiliatePayoutProps>
                            HeadRow={
                                <>
                                    <SmartTableHeadCell>Provízny list č.</SmartTableHeadCell>
                                    <SmartTableHeadCell>Popis</SmartTableHeadCell>
                                    <SmartTableHeadCell>Stav</SmartTableHeadCell>
                                    <SmartTableHeadCell>Celková suma</SmartTableHeadCell>
                                    <SmartTableHeadCell className="text-end">Akcia</SmartTableHeadCell>
                                </>
                            }
                            renderRow={(item) => {
                                return (
                                    <>
                                        <td>{item.commissionLetterNumber}</td>
                                        <td>{item.note}</td>
                                        <td>{item.status == 'PAID' ? 'Vyplatená' : item.status == 'WAITING' ? 'Čakajúca' : 'Nevyplatená'}</td>
                                        <td>{money.format(item.credit || 0)}</td>
                                        <td className="text-right">
                                            <div className="d-flex gap-2 justify-content-end">
                                                <a
                                                    href="#"
                                                    className="table-action-link"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        item.id && downloadCommisionLetter(item.commissionLetterNumber)
                                                    }}
                                                    title="Stiahnúť PDF"
                                                >
                                                    <i className="bi bi-download primary-text-color"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </>
                                )
                            }}
                        />
                    )}
                </SmartTableContext.Consumer>
            </SmartTableWrapper>
        </ClientFormWrapper>
    )
}

export default AffiliateCommisionLetters
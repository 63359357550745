import React, { useState } from 'react'

import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'

import { InvoicesListFilter } from './InvoicesListFilter'
import { generateAndDownloadInvoicePdfFile, money } from '../common/helpers'
import { SmartTable } from '../common/table/Table'
import { SmartTableContext } from '../common/table/TableContext'
import { SmartTableHeadCell } from '../common/table/TableHeadCell'
import { SmartTableWrapper } from '../common/table/TableWrapper'
import axiosClient from '../service/axiosClient'
import { PageableResponse, PagingRequest } from '../types/Common'
import { InvoiceDTO, InvoicesFilter } from '../types/Invoice'

export const InvoicesList = () => {
    const navigate = useNavigate()

    const [filterInternal, setFilterInternal] = useState<InvoicesFilter>({
        from: null,
        to: null,
    })

    function loadDataFn(queryParams: PagingRequest) {
        console.log('queryParams', queryParams)
        const pageNumber = queryParams.pageStart || 0
        const pageSize = queryParams.pageSize || 15
        return new Promise<PageableResponse<InvoiceDTO>>(
            (resolve) => {
                axiosClient.get('invoice', {
                    params: {
                        pageSize: pageSize,
                        pageStart: pageNumber,
                        from: filterInternal.from?.toISOString().slice(0, 10),
                        until: filterInternal.to?.toISOString().slice(0, 10),
                    }
                }).then(response => {
                    const totalElements = response.data.totalElements
                    const paginatedData = response.data.content
                    return resolve({
                        hasNextPage: true,
                        totalElements: totalElements,
                        content: paginatedData,
                    })
                }).catch(error => {
                    console.log('error', error)
                })
            }
        )
    }

    async function deleteInvoice(id: number, reload: () => void) {
        if (confirm('Naozaj si želáte danú faktúru vymazať?')) {
            await axiosClient.delete('invoice/' + id)
            toast.success('Faktúra bola úspešne vymazaná')
            reload()
        }
    }

    function sendPdfFile(id: string) {
        axiosClient.get('/invoice/generate/' + id).then(response => {
            axiosClient.get('/invoice/send-by-email/' + id).then(response => {
                toast.success('Faktúra bola úspešne odoslaná')
            })
        })
    }


    return <>
        <InvoicesListFilter filter={filterInternal} setFilter={setFilterInternal}/>
        {/*<div className="rounded-content-body mb-4">*/}
        <SmartTableWrapper<InvoiceDTO, InvoicesFilter>
            initialPageSize={10}
            filter={filterInternal}
            loadDataFn={loadDataFn}>
            <SmartTableContext.Consumer>
                {api => (
                    <SmartTable<InvoiceDTO>
                        onRowClick={(item) => {
                            // navigate('/invoices/edit/' + item.id)
                        }}
                        HeadRow={
                            <>
                                <SmartTableHeadCell>
                                    Číslo faktúry
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Odberateľ
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Dátum splatnosti
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Stav
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Celková suma
                                </SmartTableHeadCell>
                                <SmartTableHeadCell
                                    className="text-end">
                                    Akcia
                                </SmartTableHeadCell>
                            </>
                        }
                        renderRow={(item) => {
                            return (
                                <>
                                    <td>{item.invoiceId}</td>
                                    <td>{item.customerBusinessName}</td>
                                    <td>{item.invoiceDueDate ? new Date(item.invoiceDueDate).toLocaleDateString('sk-SK') : '-'}</td>
                                    <td>{item.invoiceStatus === 'PAID' ? 'Zaplatená' : 'Nezaplatená'}</td>
                                    <td>{money.format(item.totalWithDph ? item.totalWithDph : 0)}</td>
                                    <td className="text-right">
                                        <div className="d-flex gap-2 justify-content-end">
                                            <a href={'#'} className={'table-action-link'} onClick={(e) => {
                                                e.preventDefault()
                                                sendPdfFile(item.id.toString())
                                            }} title={'Odoslať na e-mail'}>
                                                <i className="bi bi-envelope-at primary-text-color"></i>
                                                {/*<button className="btn amani-primary amani-small">Upraviť</button>*/}
                                            </a>
                                            <a href={'#'} className={'table-action-link'} onClick={(e) => {
                                                e.preventDefault()
                                                generateAndDownloadInvoicePdfFile(item.id.toString(), item.invoiceId.toString())
                                            }} title={'Stiahnúť PDF'}>
                                                <i className="bi bi-download primary-text-color"></i>
                                                {/*<button className="btn amani-primary amani-small">Upraviť</button>*/}
                                            </a>


                                            <Link className={'table-action-link'} to={'/app/invoices/edit/' + item.id}
                                                  title={'Upraviť'}>
                                                <i className="bi bi-pencil primary-text-color"></i>
                                                {/*<button className="btn amani-primary amani-small">Upraviť</button>*/}
                                            </Link>
                                            <a href={'#'} className={'table-action-link'} onClick={(e) => {
                                                e.preventDefault()
                                                item.id && deleteInvoice(item.id, () => api.load())
                                            }} title={'Vymazať'}>
                                                <i className="bi bi-trash primary-text-color"></i>
                                                {/*<button className="btn amani-primary amani-small">Upraviť</button>*/}
                                            </a>
                                        </div>
                                    </td>
                                </>
                            )
                        }}
                    />)}</SmartTableContext.Consumer>
        </SmartTableWrapper>
        {/*</div>*/}
    </>
}
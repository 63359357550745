import React, { useEffect } from 'react'

import { Link, useNavigate, useParams } from 'react-router-dom'

import { PostForm } from './PostForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'

export const PostsEditPage = () => {
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (isNaN(parseInt(params.postId as string)) || parseInt(params.postId as string) <= 0) {
            navigate('/app/posts')
        }
    }, [params.postId])
    return (
        <AppLayout>
            <AppTitle append={
                <Link to={'/app/posts'}>
                    <button className="btn amani-primary">Späť</button>
                </Link>}>Príspevok #{params.postId}</AppTitle>

            <PostForm id={parseInt(params.postId as string)}/>
        </AppLayout>
    )
}
import React, { useEffect, useState } from 'react'

import { Table } from 'react-bootstrap'

import { ClientFormWrapper } from '../common/styledComponents'

//TODO define API_PATHS.promo
const API_PATHS = {
    promo: `${process.env.REACT_APP_SERVER_URL}/api/affiliate-promo`
}

export const fetchPromoFiles = async (): Promise<string[]> => {
    return [
        '1banner 250x250.jpg',
        '1banner 300x250.jpg',
        '1banner 300x600.jpg',
        '2banner 250x250.jpg',
        '2banner 300x250.jpg',
        '2banner 300x600.jpg',
        '3banner 250x250.jpg',
        '3banner 300x250.jpg',
        '3banner 300x600.jpg',
        '4banner 250x250.jpg',
        '4banner 300x250.jpg',
        '4banner 300x600.jpg',
        'banner 320x100.jpg'
    ]
}

const AffiliatePromo = () => {
    const [files, setFiles] = useState<string[]>([])
    useEffect(() => {
        fetchPromoFiles().then(setFiles).catch(console.error)
    }, [])

    const downloadFile = (fileName: string) => {
        const link = document.createElement('a')
        link.href = `/promo/${fileName}`
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <ClientFormWrapper>
            <div className="row">
                <div className="col-10 ">
                    {/*                 <div className="row" style={{minHeight:'200px'}}><strong>materíal 1</strong></div>
                <div className="row" style={{minHeight:'200px'}}><strong>materíal 2</strong></div>
                <div className="row" style={{minHeight:'200px'}}><strong>materíal 3</strong></div> */}
                </div>
                <div className="col">
                    <Table>
                        <thead>
                        <tr>
                            <th>Názov súboru</th>
                            <th>Akcia</th>
                        </tr>
                        </thead>
                        <tbody>
                        {files.map(file => (
                            <tr key={file}>
                                <td className={'align-middle'}>{file}</td>
                                <td>
                                    <button
                                        onClick={() => downloadFile(file)}
                                        className="d-flex flex-column align-items-center p-3 px-5 border border-2 rounded"
                                        style={{backgroundColor: '#ffecc4', width: '150px'}}
                                    >
                                        Stiahnuť
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {/*<button onClick={() => download()}*/}
                    {/*        className="d-flex flex-column align-items-center p-3 px-5 border border-2 rounded"*/}
                    {/*        style={{backgroundColor: '#ffecc4', width: '150px'}}>stiahnúť*/}
                    {/*</button>*/}
                </div>
            </div>
        </ClientFormWrapper>
    )
}

export default AffiliatePromo
import React, {useEffect, useState} from 'react'

import {Field, Form, Formik} from 'formik'
import {pick} from 'lodash'
import {Col, Container, Row} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import AsyncSelect from 'react-select/async'

import hands from '../assets/amani-thumb_up.png'
import {Gender, useAuth} from '../common/Auth'
import FormErrorMessage from '../common/FormErrorMessage'
import {loadOptionsForCompanySelector} from '../common/helpers'
import {KeyIconSvg} from '../common/KeyIconSvg'
import NiceButton from '../common/NiceButton'
import PublicLayout from '../common/PublicLayout'
import {PriceTable} from '../home/PriceTable'
import axiosClient from '../service/axiosClient'
import {Package, PaymentPeriod} from '../types/gopay'


function RegisterPage() {
    const auth = useAuth()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {t} = useTranslation()
    const [step, setStep] = useState<number>(1)

    const [searchParams,] = useSearchParams()
    const selectedUrlPackage = searchParams.get('package')
    const selectedUrlPeriod = searchParams.get('period')
    const [selectedPackage, setSelectedPackage] = useState<Package | null>(
        selectedUrlPackage && Object.values(Package).includes(selectedUrlPackage as Package)
            ? selectedUrlPackage as Package
            : null
    )
    const [selectedPeriod, setSelectedPeriod] = useState<PaymentPeriod | null>(
        selectedUrlPeriod && Object.values(PaymentPeriod).includes(selectedUrlPeriod as PaymentPeriod)
            ? selectedUrlPeriod as PaymentPeriod
            : null
    )
    console.log('selectedPackage', selectedPackage)
    // package
    const [errorMsg, setErrorMsg] = useState<string>('')
    const [successMsg, setSuccessMsg] = useState<boolean>(false)
    const [passwordShown, setPasswordShown] = useState(false)
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)

    useEffect(() => {
        setSelectedPackage(
            selectedUrlPackage && Object.values(Package).includes(selectedUrlPackage as Package)
                ? selectedUrlPackage as Package
                : null
        )
        setSelectedPeriod(
            selectedUrlPeriod && Object.values(PaymentPeriod).includes(selectedUrlPeriod as PaymentPeriod)
                ? selectedUrlPeriod as PaymentPeriod
                : null
        )
    }, [selectedUrlPackage, selectedUrlPeriod])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const defaultValues = {
        isCompany: false,
        businessName: '',
        billingStreet: '',
        billingCity: '',
        billingZipCode: '',
        icDph: '',
        dic: '',
        ico: '',
        email: '',
        password: '',
        confirmPassword: '',
        title: '',
        firstName: '',
        lastName: '',
        city: '',
        zipCode: '',
        country: '',
        street: '',
        web: '',
        phoneNumber: '',
        gender: undefined,
        getdph7aParagraph: false,
        vatPayer: false,
        acceptTerms: false,
        acceptTerms2: false,
        acceptTerms3: false,
        acceptTerms4: false,
        affiliateId: '',
    }

    const submit = async (values: typeof defaultValues) => {
        console.log('step', step)
        if (step === 1 || !selectedPackage || !selectedPeriod) {
            return
        }
        setErrorMsg('')
        setSuccessMsg(false)
        setIsLoading(true)
        const affiliateUuid = localStorage.getItem('affiliate-uuid')
        if (affiliateUuid) {
            values.affiliateId = affiliateUuid
            localStorage.removeItem('affiliate-uuid')
        }

        auth.register({
            ...pick(values, ['email', 'password', 'firstName', 'lastName', 'title', 'gender']),
            ...(values.isCompany ? {
                isCompany: values.isCompany,
                businessName: values.businessName,
                getdph7aParagraph: values.getdph7aParagraph,
                vatPayer: values.vatPayer,
                billingStreet: values.billingStreet,
                billingCity: values.billingCity,
                billingZipCode: values.billingZipCode,
                ico: values.ico,
                icDph: values.icDph,
                dic: values.dic,
            } : {
                address: {
                    street: values.street,
                    city: values.city,
                    zipCode: values.zipCode,
                    country: values.country,
                }
            }),
            contact: {
                countryPrefix: '+421',
                phoneNumber: values.phoneNumber,
                email: values.email,
                web: values.web,
            },
            // if affiliateUuid is set, it will be used, otherwise if it is empty string, it will be ignored
            affiliateId: affiliateUuid || undefined,
            selectedPackage: selectedPackage
        }).then((response) => {
            console.log('reg response', response)
            const userId = response.data.id

            if (selectedPackage !== Package.FREE) {
                axiosClient.post('/gopay/initialization', {
                    'paymentPeriod': selectedPeriod,
                    'selectedPackage': selectedPackage,
                    'userAccountId': userId,
                }).then((initGoPayResponse) => {
                    const redirectUrl = initGoPayResponse.data.gwUrl
                    window.location.href = redirectUrl
                })
            }
            setSuccessMsg(true)
        }).catch((error) => {
            console.log('error?.response?.data', error?.response?.data)
            setErrorMsg(typeof error?.response?.data === 'string' ? error?.response?.data : 'Something went wrong')
            // formikHelpers.setFieldError('email', error?.response?.data || error?.response?.data?.message || 'Something went wrong')
            // window.scrollTo(0, 0)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const validate = (values: any) => {
        const errors: any = {}
        console.log('validujeme')
        if (step === 1) {
            if (!values.email.trim()) {
                errors.email = t('required_field_error')
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Nesprávny formát e-mailovej adresy'
            }

            if (!values.password.trim()) {
                errors.password = t('required_field_error')
            } else if (values.password.length < 4) {
                errors.password = 'Heslo musí mať viac ako 4 znaky'
            }

            if (!values.confirmPassword.trim()) {
                errors.confirmPassword = t('required_field_error')
            } else if (values.confirmPassword !== values.password) {
                errors.confirmPassword = 'Heslá sa musia zhodovať'
            }

        } else {
            if (!values.firstName.trim()) {
                errors.firstName = t('required_field_error')
            }

            if (!values.lastName.trim()) {
                errors.lastName = t('required_field_error')
            }
            console.log('sme tuu vo validacii')
            if (values.isCompany) {
                if (!values.businessName.trim()) {
                    errors.businessName = t('required_field_error')
                }
                if (!(new String(values.ico)).trim()) {
                    errors.ico = t('required_field_error')
                }
                if (!(new String(values.dic)).trim()) {
                    errors.dic = t('required_field_error')
                }
                if (!values.billingStreet.trim()) {
                    errors.billingstreet = t('required_field_error')
                }
                if (!values.billingCity.trim()) {
                    errors.billingCity = t('required_field_error')
                }
                if (!values.billingZipCode.trim()) {
                    errors.billingZipCode = t('required_field_error')
                }

                if (values.vatPayer || values.getdph7aParagraph) {
                    if (!(new String(values.icDph)).trim()) {
                        errors.icDph = t('required_field_error')
                    }
                }

            } else {
                if (!values.street.trim()) {
                    errors.street = t('required_field_error')
                }
                if (!values.city.trim()) {
                    errors.city = t('required_field_error')
                }
                if (!values.zipCode.trim()) {
                    errors.zipCode = t('required_field_error')
                }
            }

            // if (!values.country.trim()) {
            //     errors.country = t('required_field_error')
            // }


            if (!values.acceptTerms) {
                errors.acceptTerms = 'Musíte súhlasiť s podmienkami'
            }
            if (!values.acceptTerms2) {
                errors.acceptTerms2 = 'Musíte súhlasiť s podmienkami'
            }
            if (!values.acceptTerms3) {
                errors.acceptTerms3 = 'Musíte súhlasiť s podmienkami'
            }
            if (!values.acceptTerms4) {
                errors.acceptTerms4 = 'Musíte súhlasiť s podmienkami'
            }
        }
        console.log('errors', errors)
        return errors
    }

    return (
        <PublicLayout>


            {!selectedPackage || !selectedPeriod ? <Container><Row>
                    <div className="col-md-12"><PriceTable/></div>
                </Row></Container> :
                (successMsg ?
                    <Container>
                        <div className="row d-flex justify-content-center py-5">
                            <div className="col-md-5">
                                <h2 className="text-center mb-5">Už len jeden malý krôčik…</h2>
                                <div className="form-text text-center">
                                    Práve som ti poslala odkaz na aktiváciu tvojho Amani účtu na zadaný
                                    e-mail.<br/>
                                    Prosím o trpezlivosť, mal by ti pristáť v tvojej schránke do 5 minút.<br/>
                                    Nie je potrebné pozerať na hodinky, ale ak sa zdá, že sa pošta zatúlala,
                                    skontroluj
                                    aj
                                    priečinok SPAM (občas sa e-maily chcú hrať na schovávačku).
                                    <br/><br/><img src={hands} width={200} alt="congrats"/>
                                </div>
                            </div>
                        </div>
                    </Container> :
                    <Container>
                        <div className="row d-flex justify-content-center py-5">
                            <div className="col-md-5">
                                <h2 className="text-center mb-5">Registrácia</h2>
                                <div className="rounded-content-body">
                                    <Formik
                                        initialValues={defaultValues}
                                        validate={validate}
                                        onSubmit={submit}
                                    >{({
                                           values,
                                           validateForm,
                                           setFieldValue,
                                           setFieldTouched,
                                           errors,
                                           isSubmitting
                                       }) => {
                                        return (<>
                                            <Form>
                                                {/* <PrettyPrintJson data={values} /> */}
                                                {step === 1 ? <>
                                                    <div className="form-group">
                                                        <label htmlFor="email">Zadaj svoju e-mailovú adresu*</label>
                                                        <Field id="email" name="email" className="form-control"/>
                                                        <FormErrorMessage name="email"/>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="password">Zvoľ heslo*</label>
                                                        <div className="input-group show-psw-input-group">
                                                            <Field id="password"
                                                                   type={passwordShown ? 'text' : 'password'}
                                                                   name="password" className="form-control"/>
                                                            <div className="input-group-append">
                                                                <button
                                                                    onClick={(e: any) => setPasswordShown(!passwordShown)}
                                                                    className="" type="button"
                                                                    title={passwordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                                                    <KeyIconSvg/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <FormErrorMessage name="password"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="confirmPassword">Zadaj heslo znova*</label>
                                                        <div className="input-group show-psw-input-group">
                                                            <Field id="confirmPassword"
                                                                   type={confirmPasswordShown ? 'text' : 'password'}
                                                                   name="confirmPassword" className="form-control"/>
                                                            <div className="input-group-append">
                                                                <button
                                                                    onClick={(e: any) => setConfirmPasswordShown(!confirmPasswordShown)}
                                                                    className="" type="button"
                                                                    title={confirmPasswordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                                                    <KeyIconSvg/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <FormErrorMessage name="confirmPassword"/>
                                                    </div>

                                                </> : <>

                                                    <h4 className={'text-left mb-3'}>Meno</h4>

                                                    {/*<div className="form-group">*/}
                                                    {/*    <label htmlFor="title">Titul</label>*/}
                                                    {/*    <Field id="title" name="title" className="form-control"/>*/}
                                                    {/*    <FormErrorMessage name="title"/>*/}
                                                    {/*</div>*/}

                                                    <div className="form-group">
                                                        <label htmlFor="firstName">Meno*</label>
                                                        <Field id="firstName" name="firstName"
                                                               className="form-control"/>
                                                        <FormErrorMessage name="firstName"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="lastName">Priezvisko*</label>
                                                        <Field id="lastName" name="lastName" className="form-control"/>
                                                        <FormErrorMessage name="lastName"/>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Pohlavie</label>
                                                        <div className="mb-3">
                                                            <div className="form-check form-check-inline">
                                                                <Field type="radio" id="gender-male"
                                                                       className="form-check-input"
                                                                       name="gender"
                                                                       value={Gender.MALE}/>
                                                                <label className="form-check-label"
                                                                       htmlFor="gender-male">
                                                                    Muž
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <Field type="radio" id="gender-female"
                                                                       className="form-check-input"
                                                                       name="gender" value={Gender.FEMALE}/>
                                                                <label className="form-check-label"
                                                                       htmlFor="gender-female">
                                                                    Žena
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <FormErrorMessage name="gender"/>
                                                    </div>

                                                    <div className="form-group form-check">
                                                        <Field type="checkbox" id="isCompany"
                                                               className="form-check-input"
                                                               name="isCompany"/>
                                                        <label className="form-check-label" htmlFor={'isCompany'}>
                                                            Chcem sa registrovať ako firma
                                                        </label>
                                                    </div>

                                                    {values.isCompany && <>
                                                        <div className="form-group">
                                                            <label htmlFor="businessName">Názov firmy*</label>
                                                            <AsyncSelect
                                                                placeholder={'Začnite písať názov firmy...'}
                                                                className={'async-select'}
                                                                classNamePrefix={'async-select'}
                                                                loadingMessage={(inputValue) => <>Vyhľadávam...</>}
                                                                noOptionsMessage={(inputValue) => <>{inputValue.inputValue.length ? 'Nenašli sa žiadne výsledky' : 'Začnite písať...'}</>}
                                                                onChange={(item) => {
                                                                    console.log('sel', item)

                                                                    setFieldValue('businessName', item?.label)

                                                                    setFieldValue('ico', item?.value?.cin)
                                                                    setFieldValue('dic', item?.value?.tin)
                                                                    setFieldValue('icDph', item?.value?.vatin || '')
                                                                    setFieldValue('billingStreet', item?.value?.formatted_street)
                                                                    setFieldValue('billingCity', item?.value?.municipality)
                                                                    setFieldValue('billingZipCode', item?.value?.postal_code)

                                                                    setFieldValue('vatPayer', false)
                                                                    setFieldValue('getdph7aParagraph', false)
                                                                    if (item?.value?.vatin_paragraph === '§4') {
                                                                        setFieldValue('vatPayer', true)
                                                                        setFieldValue('getdph7aParagraph', false)
                                                                    }
                                                                    if (item?.value?.vatin_paragraph === '§7' || item?.value?.vatin_paragraph === '§7a') {
                                                                        setFieldValue('getdph7aParagraph', true)
                                                                        setFieldValue('vatPayer', false)
                                                                    }

                                                                    // setFieldValue('providerDph7aParagraph', item?.value?.vatin_paragraph === '§7a')
                                                                }}
                                                                cacheOptions
                                                                loadOptions={loadOptionsForCompanySelector}/>

                                                            <Field id="businessName" name="businessName"
                                                                   className="form-contro d-none"/>
                                                            <FormErrorMessage name="businessName"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="businessName">IČO*</label>
                                                            <Field id="ico" name="ico" className="form-control"/>
                                                            <FormErrorMessage name="ico"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="businessName">DIČ*</label>
                                                            <Field id="dic" name="dic" className="form-control"/>
                                                            <FormErrorMessage name="dic"/>
                                                        </div>

                                                        <div className="form-group form-check">
                                                            <input
                                                                name="vatPayer"
                                                                onChange={(e) => {
                                                                    setFieldValue('vatPayer', false)
                                                                    setFieldValue('getdph7aParagraph', false)
                                                                }}
                                                                checked={!values.vatPayer && !values.getdph7aParagraph}
                                                                type="checkbox"
                                                                id="vatPayerFalse"
                                                                className="form-check-input"/>
                                                            <label className="form-check-label" htmlFor="vatPayerFalse">
                                                                Subjekt nie je platiteľ DPH
                                                            </label>
                                                        </div>
                                                        <div className="form-group form-check">
                                                            <input
                                                                name="vatPayer"
                                                                onChange={(e) => {
                                                                    setFieldValue('vatPayer', true)
                                                                    setFieldValue('getdph7aParagraph', false)
                                                                }}
                                                                checked={values.vatPayer && !values.getdph7aParagraph}
                                                                type="checkbox"
                                                                id="vatPayerTrue"
                                                                className="form-check-input"/>
                                                            <label className="form-check-label" htmlFor="vatPayerTrue">
                                                                Subjekt je platiteľ DPH
                                                            </label>
                                                        </div>
                                                        <div className="form-group form-check">
                                                            <input
                                                                name="vatPayer"
                                                                onChange={(e) => {
                                                                    setFieldValue('vatPayer', false)
                                                                    setFieldValue('getdph7aParagraph', true)
                                                                }}
                                                                checked={values.getdph7aParagraph}
                                                                type="checkbox"
                                                                id="getdph7aParagraph"
                                                                className="form-check-input"/>
                                                            <label className="form-check-label"
                                                                   htmlFor={'getdph7aParagraph'}>
                                                                Subjekt je registrovaný podľa §7/§7a
                                                            </label>
                                                        </div>

                                                        {/*<div className="form-group form-check">*/}
                                                        {/*    <Field type="checkbox" id="vatPayer" className="form-check-input"*/}
                                                        {/*           name="vatPayer"/>*/}
                                                        {/*    <label className="form-check-label" htmlFor={'vatPayer'}>*/}
                                                        {/*        Subjekt je platiteľ DPH*/}
                                                        {/*    </label>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="form-group form-check">*/}
                                                        {/*    <Field type="checkbox" id="getdph7aParagraph"*/}
                                                        {/*           className="form-check-input"*/}
                                                        {/*           name="getdph7aParagraph"/>*/}
                                                        {/*    <label className="form-check-label" htmlFor={'getdph7aParagraph'}>*/}
                                                        {/*        Subjekt je registrovaný podľa §7/§7a*/}
                                                        {/*    </label>*/}
                                                        {/*</div>*/}

                                                        {values.vatPayer || values.getdph7aParagraph ?
                                                            <div className="form-group">
                                                                <label htmlFor="icDph">IČ DPH*</label>
                                                                <Field id="icDph" name="icDph"
                                                                       className="form-control"/>
                                                                <FormErrorMessage name="icDph"/>
                                                            </div> : ''}
                                                    </>}

                                                    <h4 className={'text-left mb-3 mt-4'}>Adresa</h4>
                                                    <div className="form-group">
                                                        <label htmlFor="title">Ulica*</label>
                                                        <Field id="street"
                                                               name={values.isCompany ? 'billingStreet' : 'street'}
                                                               className="form-control"/>
                                                        <FormErrorMessage
                                                            name={values.isCompany ? 'billingStreet' : 'street'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="zipCode">PSČ*</label>
                                                        <Field id="zipCode"
                                                               name={values.isCompany ? 'billingZipCode' : 'zipCode'}
                                                               className="form-control"/>
                                                        <FormErrorMessage
                                                            name={values.isCompany ? 'billingZipCode' : 'zipCode'}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="city">Mesto*</label>
                                                        <Field id="city"
                                                               name={values.isCompany ? 'billingCity' : 'city'}
                                                               className="form-control"/>
                                                        <FormErrorMessage
                                                            name={values.isCompany ? 'billingCity' : 'city'}/>
                                                    </div>
                                                    {!values.isCompany && <div className="form-group">
                                                        <label htmlFor="country">Krajina*</label>
                                                        <Field id="country" name="country" className="form-control"/>
                                                        <FormErrorMessage name="country"/>
                                                    </div>}

                                                    <h4 className={'text-left mb-3 mt-4'}>Kontaktné údaje</h4>
                                                    <div className="form-group">
                                                        <label htmlFor="phoneNumber">Telefón</label>
                                                        <Field id="phoneNumber" name="phoneNumber"
                                                               className="form-control"/>
                                                        <FormErrorMessage name="phoneNumber"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="web">Web</label>
                                                        <Field id="web" name="web" className="form-control"/>
                                                        <FormErrorMessage name="web"/>
                                                    </div>


                                                    {/*<div className="form-group">*/}
                                                    {/*    <label htmlFor="dateOfBirth">Dátum narodenia*</label>*/}
                                                    {/*    <DatePicker*/}
                                                    {/*        showYearDropdown*/}
                                                    {/*        scrollableYearDropdown*/}
                                                    {/*        yearDropdownItemNumber={100}*/}
                                                    {/*        maxDate={maxDate.current}*/}
                                                    {/*        minDate={minDate.current}*/}
                                                    {/*        dateFormat="dd.MM.yyyy"*/}
                                                    {/*        locale="sk"*/}
                                                    {/*        //@ts-ignore*/}
                                                    {/*        selected={(values.dateOfBirth && new Date(values.dateOfBirth)) || null}*/}
                                                    {/*        onChange={(value: any) => {*/}
                                                    {/*            setFieldValue('dateOfBirth', value)*/}
                                                    {/*        }} id="dateOfBirth" name="dateOfBirth" className="form-control"/>*/}
                                                    {/*    <FormErrorMessage name="dateOfBirth"/>*/}
                                                    {/*</div>*/}


                                                    {/*<div className="form-group">*/}
                                                    {/*    <label*/}
                                                    {/*        htmlFor="file">{'Profilová fotografia'}</label>*/}
                                                    {/*    {photoUrl ?*/}
                                                    {/*        <>*/}
                                                    {/*            <img src={photoUrl} style={{width: '100px', display: 'block'}}/>*/}
                                                    {/*            <button*/}
                                                    {/*                onClick={() => {*/}
                                                    {/*                    setPhotoUrl('')*/}
                                                    {/*                    setFieldValue('photo', null)*/}
                                                    {/*                }}*/}
                                                    {/*                type="button"*/}
                                                    {/*                className="btn-jobapp-secondary mt-3">Vymazať*/}
                                                    {/*            </button>*/}
                                                    {/*        </> : null}*/}

                                                    {/*    <label className="btn btn-like-input d-block mt-1"*/}
                                                    {/*           htmlFor="file">{'Nahrať profilovú fotografiu'}</label>*/}
                                                    {/*    <input className={'d-none'} id="file" name="file" type="file"*/}
                                                    {/*           onChange={(event) => {*/}
                                                    {/*               // @ts-ignore*/}
                                                    {/*               setFieldValue('photo', event.currentTarget.files[0])*/}

                                                    {/*               if (event.target.files && event.target.files[0]) {*/}
                                                    {/*                   const reader = new FileReader()*/}
                                                    {/*                   reader.onload = (e) => {*/}
                                                    {/*                       if (e?.target?.result) {*/}
                                                    {/*                           // @ts-ignore*/}
                                                    {/*                           setPhotoUrl(e.target.result)*/}
                                                    {/*                       }*/}
                                                    {/*                   }*/}
                                                    {/*                   reader.readAsDataURL(event.target.files[0])*/}
                                                    {/*               }*/}
                                                    {/*           }}/>*/}
                                                    {/*</div>*/}

                                                    <div className="form-group form-check">
                                                        <Field type="checkbox" id="acceptTerms"
                                                               className="form-check-input"
                                                               name="acceptTerms"/>
                                                        <label className="form-check-label">
                                                            Týmto potvrdzujem, že som sa s&nbsp;<a
                                                            href={'/licencie/01_vseobecne_informacia_o_spracuvani_ou.pdf'}
                                                            target="_blank" rel="noreferrer">„Všeobecnými
                                                            informáciami o spracúvaní
                                                            osobných údajov“</a>&nbsp;oboznámil/a a zároveň sa zaväzujem
                                                            poskytovať a
                                                            používať len osobné údaje v zmysle&nbsp;<a
                                                            href={'/licencie/vlp_rev.pdf'}
                                                            target="_blank" rel="noreferrer">„Licenčných
                                                            podmienok“</a>&nbsp;a také, na
                                                            ktorých spracovanie som oprávnený/á a to všetko v súlade s
                                                            Nariadením
                                                            GDPR a ďalšími právnymi predpismi na ochranu osobných údajov
                                                            {/*Súhlasím s <Link to={'/gdpr'} target="_blank">pravidlami používania*/}
                                                            {/*Amani</Link> a <Link to={'/gdpr'} target="_blank">zásadami spracúvania*/}
                                                            {/*osobných údajov</Link>.*/} *
                                                        </label>
                                                        <FormErrorMessage name="acceptTerms"/>
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <Field type="checkbox" id="acceptTerms2"
                                                               className="form-check-input"
                                                               name="acceptTerms2"/>
                                                        <label className="form-check-label">
                                                            Týmto potvrdzujem, že som sa s&nbsp;<a
                                                            href={'/licencie/02_informacia_o_spracuvani_ou_socialne_siete_cookies.pdf'}
                                                            target="_blank" rel="noreferrer">„Informáciami o spracúvaní
                                                            osobných
                                                            údajov - sociálne siete a cookies“</a>&nbsp;oboznámil/a a
                                                            zároveň sa
                                                            zaväzujem
                                                            poskytovať a používať len osobné údaje v zmysle „Licenčných
                                                            podmienok“ a
                                                            také, na ktorých spracovanie som oprávnený/á a to všetko v
                                                            súlade s
                                                            Nariadením GDPR a ďalšími právnymi predpismi na ochranu
                                                            osobných
                                                            údajov
                                                            {/*Súhlasím s <Link to={'/gdpr'} target="_blank">pravidlami používania*/}
                                                            {/*Amani</Link> a <Link to={'/gdpr'} target="_blank">zásadami spracúvania*/}
                                                            {/*osobných údajov</Link>.*/} *
                                                        </label>
                                                        <FormErrorMessage name="acceptTerms2"/>
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <Field type="checkbox" id="acceptTerms3"
                                                               className="form-check-input"
                                                               name="acceptTerms3"/>
                                                        <label className="form-check-label">
                                                            <strong>Výslovne súhlasím</strong> so začatím poskytovania
                                                            služby
                                                            pred
                                                            uplynutím lehoty
                                                            na odstúpenie od zmluvy podľa § 17 ods. 10 písm. c) zákona
                                                            č.
                                                            108/2024
                                                            Z. z. v znení neskorších predpisov a potvrdzujem, že som
                                                            týmto
                                                            bol
                                                            osobitne poučený, že udelením súhlasu so začatím
                                                            poskytovania
                                                            služby
                                                            pred uplynutím lehoty na odstúpenie od zmluvy strácam po
                                                            úplnom
                                                            poskytnutí služby právo na odstúpenie od zmluvy podľa § 17
                                                            ods.
                                                            10
                                                            písm.
                                                            a) a b) zákona č. 108/2024 Z. z. v znení neskorších
                                                            predpisov.
                                                        </label>
                                                        <FormErrorMessage name="acceptTerms3"/>
                                                    </div>
                                                    <div className="form-group form-check">
                                                        <Field type="checkbox" id="acceptTerms4"
                                                               className="form-check-input"
                                                               name="acceptTerms4"/>
                                                        <label className="form-check-label">
                                                            Súhlasím so zasielaním Newslettra. Týmto potvrdzujem, že som
                                                            sa
                                                            s&nbsp;<a
                                                            href={'/licencie/01_vseobecne_informacia_o_spracuvani_ou.pdf'}
                                                            target="_blank" rel="noreferrer">„Všeobecná informácia o
                                                            spracúvaní osobných údajov“</a>&nbsp;oboznámil/a
                                                            a
                                                            dávam
                                                            súhlas spoločnosti The first million s. r. o., Dolné Sľažany
                                                            317,
                                                            Sľažany, IČO: 54 616 913 pre spracovanie mojich osobných
                                                            údajov
                                                            v
                                                            rozsahu titul, meno, priezvisko, e-mail za účelom zasielania
                                                            Newslettra
                                                            v zmysle uvedenej informácie a po dobu tam uvedenú.
                                                        </label>
                                                        <FormErrorMessage name="acceptTerms4"/>
                                                    </div>

                                                </>}


                                                <p className="text-center">* označené údaje sú povinné</p>

                                                {errorMsg &&
                                                    <div
                                                        className="text-danger form-text text-center mb-3">{errorMsg}</div>}

                                                {step === 1 &&
                                                    <NiceButton isLoading={isSubmitting} type="button"
                                                                onClick={async () => {

                                                                    const err = await validateForm()
                                                                    console.log('err', err)
                                                                    console.log('Object.keys(err)', Object.keys(err))
                                                                    if (!Object.keys(err).length) {
                                                                        setStep(2)
                                                                    } else {

                                                                        Object.keys(err).map((item) => {
                                                                            setFieldTouched(item, true)
                                                                        })
                                                                    }
                                                                }}
                                                                className="btn btn-primary btn-amani">
                                                        Ďalej
                                                    </NiceButton>}
                                                {step === 2 && <>
                                                    <Row>
                                                        <Col>
                                                            <button type="button" className="btn btn-primary btn-amani"
                                                                    onClick={e => setStep(1)}>
                                                                Späť
                                                            </button>
                                                        </Col>
                                                        <Col>
                                                            <NiceButton isLoading={isLoading} type="submit"
                                                                        onClick={() => {
                                                                            setFieldTouched('acceptTerms', true)
                                                                        }} className="btn btn-primary btn-jobapp">
                                                                Registrovať
                                                            </NiceButton>
                                                        </Col>


                                                    </Row>
                                                </>}

                                            </Form>
                                        </>)
                                    }}

                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </Container>)
            }

        </PublicLayout>
    )
}

export default RegisterPage

import React, { PropsWithChildren } from 'react'

import { Box } from '@mui/material'
import { Gauge, gaugeClasses } from '@mui/x-charts'
import { Dropdown } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from './Auth'
import profileIcon from '../assets/10.svg'
import logo from '../assets/logo-small.png'
import { SideCalendar } from '../dashboard/SideCalendar'

export default function AppLayout({children}: PropsWithChildren<unknown>) {
    const auth = useAuth()
    const navigate = useNavigate()

    //get packageActiveTo from user and count the remaining days until now
    const packageActiveTo = auth.user?.packageActiveTo ? new Date(auth.user?.packageActiveTo) : new Date()
    console.log('auth.user?.packageActiveTo', auth.user?.packageActiveTo)
    console.log('packageActiveTo', packageActiveTo)
    const today = new Date()
    const diffTime = packageActiveTo ? packageActiveTo.getTime() - today.getTime() : 0
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="sidebar  col-lg-2 p-0 bg-white">

                        <div
                            className="d-flex flex-lg-nowrap justify-content-between align-items-center justify-content-lg-center">
                            <a className="navbar-brand bg-white"
                               href="#">
                                <img src={logo} style={{width: '150px'}}/>
                            </a>
                            <ul className="navbar-nav flex-row d-lg-none">
                                <li className="nav-item text-nowrap">
                                    <button className="nav-link px-3 text-black" type="button"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation">
                                        Menu
                                        {/*<svg className="bi">*/}
                                        {/*    <use xlinkHref="#list"/>*/}
                                        {/*</svg>*/}
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div className="offcanvas-lg offcanvas-end bg-white" tabIndex={-1} id="sidebarMenu"
                             aria-labelledby="sidebarMenuLabel">
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="sidebarMenuLabel">{auth.user?.fullName}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                        data-bs-target="#sidebarMenu" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">


                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link to={'/app/dashboard'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-house"></i>

                                            Dashboard
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/clients'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-people"></i>

                                            Klienti
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/calendar'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-calendar"></i>
                                            Kalendár
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/posts'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-instagram"></i>
                                            Sociálne siete
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/timesheet'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-clock"></i>
                                            Timetracker
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/invoices'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">
                                            <i className="bi bi-list-ol"></i>
                                            Fakturácia
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/app/affiliate'}
                                              className="nav-link d-flex align-items-center gap-2 text-uppercase">

                                            <i className="bi bi-diagram-3"></i>

                                            Affiliate
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <main className="main-content ms-sm-auto col-lg-8 col-md-9 ps-4 pe-4">
                        {/*<TimeTrackingWidget />*/}

                        {children}
                    </main>
                    <div className="col-lg-2 col-md-3 pt-3 ps-md-0  ps-3">
                        <Dropdown className={'amani-primary-pink w-100'}>
                            <Dropdown.Toggle variant="success" className="amani-primary pink-btn w-100"
                                             id="dropdown-profile">
                                <img src={profileIcon} width={30}/>
                                Môj profil&nbsp;
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={'w-100'}>
                                <Dropdown.Item>
                                    <Link to={'/app/edit-profile'}
                                          className="nav-link d-flex align-items-center gap-2">
                                        <i className="bi bi-person-gear position-relative" style={{top: '-3px'}}></i>
                                        Môj profil
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className={'d-flex align-items-center gap-2'}
                                    onClick={() => {
                                        auth.logout(() => navigate('/'))

                                    }}>
                                    <i className="bi bi-box-arrow-left position-relative" style={{top: '-3px'}}></i>
                                    Odhlásiť sa
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className="rounded-right-sidebar mt-2">
                            <SideCalendar/>
                        </div>

                        <h5 className={'mt-3 text-center'}>Vaše predplatné končí o</h5>
                        <Box sx={{ width: '100%', height: '200px' }}>

                            <Gauge
                                value={diffDays}
                                cornerRadius="50%"
                                sx={(theme) => ({
                                    [`& .${gaugeClasses.valueText}`]: {
                                        fontSize: 30,
                                    },
                                    [`& .${gaugeClasses.valueArc}`]: {
                                        fill: '#9B5B7E',
                                    },
                                    [`& .${gaugeClasses.referenceArc}`]: {
                                        fill: '#d4a4bd',
                                    },
                                })}

                                text={(params) => {
                                    return `${params.value} dní`
                                }} />

                        </Box>





                    </div>
                </div>
            </div>
        </>

    )
}
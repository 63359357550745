import React, { useEffect, useRef, useState } from 'react'

import { Modal, Tab, Tabs } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'

import CalendarEventTypes from './CalendarEventTypes'
import ChangePswForm from './ChangePswForm'
import EmailSettingsForm from './EmailSettingsForm'
import MyInvoicesList from './MyInvoicesList'
import UpdateProfileForm from './UpdateProfileForm'
import AppLayout from '../common/AppLayout'
import AppTitle from '../common/AppTitle'
import { useAuth } from '../common/Auth'
import NiceButton from '../common/NiceButton'
import { ClientFormWrapper } from '../common/styledComponents'
import { PriceTable } from '../home/PriceTable'
import axiosClient from '../service/axiosClient'
import ClientDTO from '../types/Client'
import { Package, PaymentPeriod } from '../types/gopay'

function UpdateProfilePage() {
    const {user, logout} = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const [currentSelectedPackage, setCurrentSelectedPackage] = useState<Package | undefined>(user?.selectedPackage)
    const [currentSelectedPeriod, setCurrentSelectedPeriod] = useState<PaymentPeriod | undefined>(user?.paymentPeriod)
    const [activeKey, setActiveKey] = useState<string>('profil')
    const [showModalClientsActivation, setShowModalClientsActivation] = useState(false)
    const [clients, setClients] = useState<ClientDTO[]>([])
    const [selectedClientIds, setSelectedClientIds] = useState<number[]>([])
    const [maxClients, setMaxClients] = useState<number | null>(null)
    const newPackage = useRef<Package | undefined>(undefined)
    const newPeriod = useRef<PaymentPeriod | undefined>(undefined)
    const handleModalClientsClose = () => setShowModalClientsActivation(false)
    const handleModalClientsShow = () => setShowModalClientsActivation(true)

    // Set active tab based on the URL hash on initial load
    useEffect(() => {
        const currentHash = location.hash.replace('#', '')
        if (currentHash) {
            setActiveKey(currentHash)
        }
    }, [location.hash])

    // Update URL hash when the tab changes
    const handleSelect = (key: string | null) => {
        if (key) {
            setActiveKey(key)
            navigate(`#${key}`, {replace: true})
        }
    }

    function deleteAccount() {
        if (confirm('Naozaj chcete zrušiť účet?')) {
            axiosClient.delete('user/' + user?.id).then(() => {
                logout(() => navigate('/'))
            })
        }
    }

    function changePckg(clientsToActivate: number[], clientsToDeactivate: number[]) {
        console.log('changePckg', newPackage.current, newPeriod.current, clientsToActivate, clientsToDeactivate)
        axiosClient.post('gopay/package-change', {
            selectedPackage: newPackage.current,
            paymentPeriod: newPeriod.current,
            clientsToActivate: clientsToActivate,
            clientsToDeactivate: clientsToDeactivate
        }).then(response => {
            console.log('response', response)
            if (response.data && response.data.gwUrl) {
                const redirectUrl = response.data.gwUrl
                window.location.href = redirectUrl
            } else {
                setCurrentSelectedPackage(newPackage.current)
                setCurrentSelectedPeriod(newPeriod.current)
                toast.success('Balík bol úspešne zmenený')
            }
        }).catch(error => {
            console.log('error', error)
            toast.error('Zmenu balíka sa nepodarilo zrealizovať. Kontaktujte administrátora.')
        })
    }

    function handleClientSelection() {
        // if (user) {
        //     changePckg(user.selectedPackage, user.paymentPeriod, selectedClientIds, clients)
        // }
        // clients to unlock = all clients - selectedClientIds
        const clientsToLock = clients.filter(client => !selectedClientIds.includes(client.id as number))
        changePckg(selectedClientIds, clientsToLock.map(client => client.id as number))
        handleModalClientsClose()
    }

    async function onPackageSelect(pckg: Package, period: PaymentPeriod) {
        setSelectedClientIds([])
        newPackage.current = pckg
        newPeriod.current = period
        console.log('selected package', pckg, period)

        axiosClient.get('client').then(response => {
            console.log('response', response)
            setClients(response.data)

            if (pckg !== Package.PREMIUM) {
                switch (pckg) {
                    case Package.BASIC:
                        if (response.data.length > 3) {
                            setMaxClients(3)
                            handleModalClientsShow()
                        } else {
                            changePckg(clients.map(client => client.id as number) ?? [], [])
                        }
                        break
                    case Package.MEDIUM:
                        if (response.data.length > 7) {
                            setMaxClients(7)
                            handleModalClientsShow()
                        } else {
                            changePckg(clients.map(client => client.id as number) ?? [], [])
                        }
                        break
                    // case Package.PREMIUM:
                    //     setMaxClients(null)
                    //     break
                }
            } else {
                changePckg(clients.map(client => client.id as number) ?? [], [])
            }
        }).catch(error => {
            console.log('error', error)
        })
    }

    const handleCheckboxChange = (clientId: number) => {
        setSelectedClientIds(prevSelectedClientIds => {
            if (prevSelectedClientIds.includes(clientId)) {
                return prevSelectedClientIds.filter(id => id !== clientId)
            } else if (maxClients === null || prevSelectedClientIds.length < maxClients) {
                return [...prevSelectedClientIds, clientId]
            }
            return prevSelectedClientIds
        })
    }

    return (
        <AppLayout>
            <Modal show={showModalClientsActivation} onHide={handleModalClientsClose}>
                <Modal.Title>
                    {maxClients ? `Prosím, vyberte maximálne ${maxClients} klientov, ktorých chcete mať aktívnych` : 'Prosím, vyberte klientov, ktorých chcete mať aktívnych'}
                </Modal.Title>
                <Modal.Body>
                    {clients.map(client => (
                        <div className="form-group form-check" key={client.id}>
                            <input type="checkbox" id={`client-${client.id}`}
                                   className="form-check-input"
                                   disabled={maxClients !== null && selectedClientIds.length >= maxClients && !selectedClientIds.includes(client.id as number)}
                                   checked={selectedClientIds.includes(client.id as number)}
                                   onChange={() => {
                                       console.log('sme tu')
                                       handleCheckboxChange(client.id as number)
                                   }}
                                   name="client"/>
                            <label className="form-check-label" htmlFor={`client-${client.id}`}>
                                {client.firstName} {client.lastName}
                            </label>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>

                    <NiceButton
                        isLoading={false}
                        disabled={!(maxClients && selectedClientIds.length && selectedClientIds.length <= maxClients)}
                        onClick={handleClientSelection}>
                        Pokračovať
                    </NiceButton>
                </Modal.Footer>
            </Modal>

            <AppTitle>Nastavenia</AppTitle>

            <Tabs
                activeKey={activeKey} onSelect={handleSelect}
                id="setting-tabs"
            >
                <Tab eventKey="profil" title="Môj profil">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-8">
                                <UpdateProfileForm/>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="socials" title="Emailové nastavenia">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-8">
                                <EmailSettingsForm/>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="calendar-event-types" title="Typy udalostí v kalendári">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-12">
                                <CalendarEventTypes/>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="change-psw" title="Zmena hesla">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-8">
                                <ChangePswForm/>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="invoices" title="Faktúry">
                    <ClientFormWrapper>
                        <MyInvoicesList/>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="packages" title="Balíky">
                    <ClientFormWrapper>
                        <PriceTable defaultPackage={currentSelectedPackage} defaultPeriod={currentSelectedPeriod}
                                    onPackageSelect={onPackageSelect} hideTitle={true} hideFreePackage={true}/>
                    </ClientFormWrapper>
                </Tab>
                <Tab eventKey="cancel-account" title="Zrušenie účtu">
                    <ClientFormWrapper>
                        <div className="row d-flex">
                            <div className="col-md-8">
                                <p>
                                    Zrušením účtu príjdete o všetky svoje uložené údaje, vrátane faktúr.<br/>
                                    Preto si ich pred zrušením stiahnite. Po potvrdení bude účet a aj všetky údaje
                                    vymazané po 30 dňoch. V tomto časovom horizonte je možné zrušenie anulovať.
                                </p>

                                <NiceButton className={'dark-pink-color'} onClick={deleteAccount} type="button"
                                            isLoading={false}>
                                    Vymazať účet
                                </NiceButton>
                            </div>
                        </div>
                    </ClientFormWrapper>
                </Tab>

            </Tabs>


            {/*<ClientFormWrapper>*/}
            {/*    <div className="row d-flex">*/}
            {/*        <div className="col-md-8">*/}
            {/*            <UpdateProfileForm/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</ClientFormWrapper>*/}
        </AppLayout>
    )
}

export default UpdateProfilePage

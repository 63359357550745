import React, { useEffect, useState } from 'react'

import { Field, Form, Formik, FormikHelpers } from 'formik'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TagsInput } from 'react-tag-input-component'

import FormErrorMessage from '../common/FormErrorMessage'
import { dateToIsoString, fetchClients } from '../common/helpers'
import { datePickerDateFormat } from '../common/i18n'
import NiceButton from '../common/NiceButton'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient, { SERVER_URL } from '../service/axiosClient'
import ClientDTO from '../types/Client'
import PostDTO, {
    PostApprovalStatusType,
    PostFormData,
    PostStatusType,
    PostType,
    PublishFrequency
} from '../types/Post'
import Post from '../types/Post'
import SocialNetwork from '../types/SocialNetwork'

interface Props {
    id?: number | null
}

const publishFrequencyOptions = [
    {value: PublishFrequency.ONCE, label: 'Jednorázovo'},
    {value: PublishFrequency.DAILY, label: 'Denne'},
    {value: PublishFrequency.WEEKLY, label: 'Týždenne'},
    {value: PublishFrequency.MONTHLY, label: 'Mesačne'},
]

const socialNetworkOptions = [
    {value: SocialNetwork.INSTAGRAM, label: 'Instagram'},
    {value: SocialNetwork.FACEBOOK, label: 'Facebook'},
    {value: SocialNetwork.LINKED_IN, label: 'Linked In'},
]

const postTypeOptions = [
    {value: PostType.POST, label: 'Post'},
    {value: PostType.STORY, label: 'Story'},
    {value: PostType.REEL, label: 'Reel'},
]
const postStatusOptions = [
    {value: PostStatusType.NOT_PUBLISHED, label: 'Nepublikovaný'},
    {value: PostStatusType.PUBLISHED, label: 'Publikovaný'},
]
const postApprovalStatusOptions = [
    {value: PostApprovalStatusType.APPROVED, label: 'Schválený'},
    {value: PostApprovalStatusType.DECLINED, label: 'Zamietnutý'},
    {value: PostApprovalStatusType.CHANGE_REQUIRED, label: 'Vyžiadaná zmena'},
]

export const PostForm = ({id}: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchParams,] = useSearchParams()
    const queryClientId = searchParams.get('clientId')
    const queryStart = searchParams.get('start')
    const queryEnd = searchParams.get('end')

    const navigate = useNavigate()
    const [clients, setClients] = useState<ClientDTO[]>([])
    const [post, setPost] = useState<PostFormData>({
        title: '',
        description: '',
        clientId: queryClientId || '',
        postType: '' as unknown as PostType,
        socialNetwork: '' as unknown as SocialNetwork,
        publishFrequency: '' as unknown as PublishFrequency,
        postApprovalStatus: '' as unknown as PostApprovalStatusType,
        postStatus: '' as unknown as PostStatusType,
        photo: '',
        showInCalendar: false,
        hashTags: [],
        publishAt: '',
        publishFrom: queryStart ? new Date(queryStart) : null,
        publishUntil: queryEnd ? new Date(queryEnd) : null,
    } as PostFormData)
    const {t} = useTranslation()
    const [key, setKey] = useState('initial')
    const [photoUrl, setPhotoUrl] = useState<string>('')


    useEffect(() => {
        fetchClients().then(setClients).catch(console.error)
    }, [])

    useEffect(() => {
        if (id && id > 0) {
            axiosClient.get('post/' + id)
                .then(response => {
                    console.log('response', response)
                    const postTmp = response.data
                    setPost({
                        ...postTmp,
                        hashTags: postTmp.hashTags.split(','),
                        publishUntil: postTmp.publishUntil ? new Date(postTmp.publishUntil) : null,
                        publishFrom: postTmp.publishFrom ? new Date(postTmp.publishFrom) : null,
                        // publishAt: postTmp.publishAt ? new Date(postTmp.publishAt) : null,
                        // publishAt: null,
                        publishFrequency: postTmp.publishFrequency as PublishFrequency,
                        socialNetwork: postTmp.socialNetwork as SocialNetwork
                    })
                    if (postTmp.photo) {
                        setPhotoUrl(`${SERVER_URL}/storage/${postTmp.photo}`)
                    }
                })

        }
    }, [id])

    const submit = async (values: PostFormData, actions: FormikHelpers<PostFormData>) => {
        console.log('values', values)


        try {
            // const response = await updateProfile({...values})
            setIsLoading(true)
            const request: Omit<PostDTO, 'id' | 'clientLastName' | 'clientFirstName'> & { id?: number } = {
                title: values.title ?? '',
                description: values.description ?? '',
                clientId: values.clientId as unknown as number,
                postType: values.postType as PostType,
                socialNetwork: values.socialNetwork as SocialNetwork,
                publishFrequency: values.publishFrequency as PublishFrequency,
                postApprovalStatus: values.postApprovalStatus as PostApprovalStatusType,
                postStatus: values.postStatus as PostStatusType,
                photo: values.photo as string,
                showInCalendar: values.showInCalendar,
                hashTags: '',
                publishAt: values.publishAt,
                publishFrom: '',
                publishUntil: '',
            }

            if (id && id > 0) {
                request.id = id
            }

            request.hashTags = values.hashTags?.join(',') ?? ''

            // if (values.publishAt) {
            //     const publishAt = new Date(values.publishAt)
            //     const hours = publishAt.getHours().toString().padStart(2, '0')
            //     const minutes = publishAt.getMinutes().toString().padStart(2, '0')
            //
            //     request.publishAt = `${hours}:${minutes}`
            // }
            if (values.publishFrom) {
                request.publishFrom = dateToIsoString((new Date(values.publishFrom)))
            }
            if (values.publishUntil) {
                request.publishUntil = dateToIsoString((new Date(values.publishUntil)))
            }

            const response = await axiosClient.post('post', request)

            if (id) {
                window.scrollTo(0, 0)
                toast.success('Príspevok bol úspešne upravený')
            } else if (response.data > 0) {
                navigate('/app/posts')
                toast.success('Príspevok bol úspešne vytvorený')
            }
            // actions.resetForm()
        } catch (error: any) {
            toast.error('Príspevok sa nepodarilo uložiť')
        } finally {
            setIsLoading(false)
        }
    }

    const validate = (values: Partial<PostFormData>) => {
        const errors: any = {}

        if (!values?.clientId) {
            errors.clientId = t('required_field_error')
        }
        if (!values?.socialNetwork) {
            errors.socialNetwork = t('required_field_error')
        }
        if (!values?.publishFrequency) {
            errors.publishFrequency = t('required_field_error')
        }
        if (!values?.publishAt) {
            errors.publishAt = t('required_field_error')
        }
        if (!values?.title?.trim()) {
            errors.title = t('required_field_error')
        }

        if (!values?.description?.trim()) {
            errors.description = t('required_field_error')
        }

        return errors
    }

    return (
        <ClientFormWrapper>
            <div className="row d-flex">
                <div className="col-md-8">
                    <Formik
                        initialValues={post}
                        enableReinitialize
                        validate={validate}
                        onSubmit={submit}
                    >{({values, setFieldValue}) => (<>
                        <Form>
                            <div
                                className={'form-group'}>
                                <label htmlFor="clientId">
                                    Klient
                                </label>
                                <Field as="select" id="clientId" name="clientId" className="form-select">
                                    <option value="">Vyberte</option>
                                    {clients.map((option) => (
                                        <option key={option.id}
                                                value={option.id}>{option.companyName || (`${option.firstName} ${option.lastName}`)}</option>))}
                                </Field>
                                <FormErrorMessage name="clientId"/>
                            </div>

                            <div
                                className={'form-group'}>
                                <label htmlFor="socialNetwork">
                                    Sociálna sieť
                                </label>
                                <Field as="select" id="socialNetwork" name="socialNetwork" className="form-select">
                                    <option value="">Vyberte</option>
                                    {socialNetworkOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>))}
                                </Field>
                                <FormErrorMessage name="socialNetwork"/>
                            </div>

                            <div
                                className={'form-group'}>
                                <label htmlFor="postType">Typ príspevku</label>
                                <Field as="select" id="postType" name="postType" className="form-select">
                                    <option value="">Vyberte</option>
                                    {postTypeOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>))}
                                </Field>
                                <FormErrorMessage name="postType"/>
                            </div>

                            <div
                                className={'form-group'}>
                                <label htmlFor="postStatus">Status</label>
                                <Field as="select" id="postStatus" name="postStatus" className="form-select">
                                    <option value="">Vyberte</option>
                                    {postStatusOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>))}
                                </Field>
                                <FormErrorMessage name="postStatus"/>
                            </div>
                            <div
                                className={'form-group'}>
                                <label htmlFor="postApprovalStatus">Status akceptácie</label>
                                <Field as="select" id="postApprovalStatus" name="postApprovalStatus" className="form-select">
                                    <option value="">Vyberte</option>
                                    {postApprovalStatusOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>))}
                                </Field>
                                <FormErrorMessage name="postApprovalStatus"/>
                            </div>

                            <div
                                className={'form-group'}>
                                <label htmlFor="publishFrequency">
                                    Frekvencia publikovania
                                </label>
                                <Field as="select" id="publishFrequency" name="publishFrequency"
                                       className="form-select">
                                    <option value="">Vyberte</option>
                                    {publishFrequencyOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>))}
                                </Field>
                                <FormErrorMessage name="publishFrequency"/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="title">Názov</label>
                                <Field id="title" name="title" className="form-control"/>
                                <FormErrorMessage name="title"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="description">Popis</label>
                                <Field as="textarea" rows={5} id="description" name="description"
                                       className="form-control"/>
                                <FormErrorMessage name="description"/>
                            </div>

                            <div className="form-group form-check">
                                <Field type="checkbox" id="showInCalendar" className="form-check-input"
                                       name="showInCalendar"/>
                                <label htmlFor={'showInCalendar'} className="form-check-label">
                                    Zobraziť v kalendári
                                </label>
                            </div>


                            <div className="form-group">
                                <label>Hashtagy</label>
                                <TagsInput
                                    value={values.hashTags}
                                    onChange={(tags) => setFieldValue('hashTags', tags)}
                                    name="hashtags"
                                    placeHolder="Zadajte a stlačte enter"
                                />
                            </div>

                            {/*<h4 className="text-left">Významné udalosti</h4>*/}
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="publishAt">Čas uverejnenia</label>
                                        <input
                                            className="form-control"
                                            type="time"
                                            value={values.publishAt}
                                            id="publishAt"
                                            name="publishAt"
                                            onChange={(e) => setFieldValue('publishAt', e.target.value)}/>
                                        {/*<DatePicker*/}
                                        {/*    showTimeSelect*/}
                                        {/*    showTimeSelectOnly*/}
                                        {/*    timeIntervals={1}*/}
                                        {/*    timeCaption="Time"*/}
                                        {/*    dateFormat="HH:mm"*/}
                                        {/*    locale="sk"*/}
                                        {/*    selected={(values.publishAt && new Date(values.publishAt)) || null}*/}
                                        {/*    onChange={(value: any) => {*/}
                                        {/*        console.log('value', value)*/}
                                        {/*        setFieldValue('publishAt', value)*/}
                                        {/*    }}*/}
                                        {/*    id="publishAt"*/}
                                        {/*    name="publishAt"*/}
                                        {/*    className="form-control"/>*/}
                                        <FormErrorMessage name="publishAt"/>
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="publishFrom">Dátum od</label>
                                        <DatePicker
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            locale="sk"
                                            dateFormat={datePickerDateFormat}
                                            showYearDropdown
                                            selected={(values.publishFrom && new Date(values.publishFrom)) || null}
                                            onChange={(value: any) => {
                                                setFieldValue('publishFrom', value)
                                            }}
                                            id="publishFrom"
                                            name="publishFrom"
                                            className="form-control"/>
                                        <FormErrorMessage name="publishFrom"/>
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="publishUntil">Dátum do</label>
                                        <DatePicker
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={100}
                                            locale="sk"
                                            dateFormat={datePickerDateFormat}
                                            showYearDropdown
                                            selected={(values.publishUntil && new Date(values.publishUntil)) || null}
                                            onChange={(value: any) => {
                                                setFieldValue('publishUntil', value)
                                            }}
                                            id="publishUntil"
                                            name="publishUntil"
                                            className="form-control"/>
                                        <FormErrorMessage name="publishUntil"/>
                                    </div>

                                </div>
                            </div>

                            <div className="form-group">
                                <label
                                    htmlFor="file">{'Obrázok'}</label>
                                {photoUrl ?
                                    <>
                                        <img key={key} src={photoUrl as unknown as string}
                                             style={{width: '100px', display: 'block'}}/>
                                        <button
                                            onClick={() => {
                                                setPhotoUrl('')
                                                setFieldValue('photo', null)
                                            }}
                                            type="button"
                                            className="button btn btn-primary btn-amani mt-3 mb-2">{'Vymazať fotografiu'}</button>
                                    </> : null}

                                <label className="btn btn-like-input d-block mt-1"
                                       htmlFor="file">{'Nahrať obrázok'}</label>
                                <input className={'d-none'} id="file" key={key} name="file" type="file"
                                       onChange={(event) => {
                                           if (event.target.files && event.target.files[0]) {
                                               const formData = new FormData()
                                               formData.append('file', event.target.files[0])

                                               axiosClient.post('storage', formData).then((resp) => {
                                                   console.log('resp', resp.data)
                                                   setFieldValue('photo', resp.data)
                                                   setPhotoUrl(`${SERVER_URL}/storage/${resp.data}`)
                                               })
                                           }
                                       }}/>
                            </div>

                            <NiceButton isLoading={isLoading} type="submit">
                                Uložiť
                            </NiceButton>
                        </Form>
                    </>)}

                    </Formik>
                </div>
            </div>
        </ClientFormWrapper>
    )
}
import React, { useEffect, useState } from 'react'

import { Field, Form, Formik } from 'formik'
import toast from 'react-hot-toast'

import FormErrorMessage from '../common/FormErrorMessage'
import { KeyIconSvg } from '../common/KeyIconSvg'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient, { SERVER_URL } from '../service/axiosClient'
import SocialNetwork from '../types/SocialNetwork'

interface ClientCredentialsDto {
    'id': number,
    'name': 'string',
    'socialNetwork': SocialNetwork,
    'username': 'string',
    'password': 'string',
    'clientId': number,
}

interface ISocialCredentials {
    INSTAGRAM: {
        login: string
        password: string
        pswShown: boolean
    }
    FACEBOOK: {
        login: string
        password: string
        pswShown: boolean
    }
    LINKED_IN: {
        login: string
        password: string
        pswShown: boolean
    }
}

interface Props {
    id: number
}

export const SocialCredentials = ({id}: Props) => {
    const [credentials, setCredentials] = useState<ISocialCredentials>({
        INSTAGRAM: {
            login: '',
            password: '',
            pswShown: false
        },
        FACEBOOK: {
            login: '',
            password: '',
            pswShown: false
        },
        LINKED_IN: {
            login: '',
            password: '',
            pswShown: false
        }
    })

    useEffect(() => {
        if (id && id > 0) {
            axiosClient.get(`/client/${id}/credentials`).then(response => {
                const instagram = response.data.find((item: ClientCredentialsDto) => item.socialNetwork === SocialNetwork.INSTAGRAM)
                const facebook = response.data.find((item: ClientCredentialsDto) => item.socialNetwork === SocialNetwork.FACEBOOK)
                const linkedin = response.data.find((item: ClientCredentialsDto) => item.socialNetwork === SocialNetwork.LINKED_IN)

                setCredentials({
                    INSTAGRAM: {
                        login: instagram?.username || '',
                        password: instagram?.password || '',
                        pswShown: false
                    },
                    FACEBOOK: {
                        login: facebook?.username || '',
                        password: facebook?.password || '',
                        pswShown: false
                    },
                    LINKED_IN: {
                        login: linkedin?.username || '',
                        password: linkedin?.password || '',
                        pswShown: false
                    }
                })
            })
        }
    }, [id])

    function connectToSocialNetwork(network: string) {
        let url = ''

        // switch network
        switch (network) {
            case 'FACEBOOK':
                url = `${SERVER_URL}/facebook/login`
                break
            case 'INSTAGRAM':
                url = `${SERVER_URL}/instagram/login`
                break
            case 'LINKED_IN':
                url = `${SERVER_URL}/linkedin/login`
                break
            default:
                toast.error('Nepodarilo sa presmerovať na prihlásenie, kontaktujte správcu')
                return
        }
        // window.location.href = url
        axiosClient.get(url).then(response => {
            console.log('res', response)
            window.location.href = response.data
        }).catch(error => {
            console.error('error', error)
            toast.error('Nepodarilo sa presmerovať na prihlásenie, kontaktujte správcu')
        })
    }

    const saveCredentials = async (values: ISocialCredentials, key: SocialNetwork) => {
        try {
            await axiosClient.post('/client/credentials', {
                socialNetwork: key,
                username: values[key].login,
                password: values[key].password,
                clientId: id
            })
        } catch (error) {
            toast.error('Prístupové údaje sa nepodarilo uložiť')
            console.error(error)
        }
    }


    return (
        <ClientFormWrapper>
            <Formik
                onSubmit={() => {
                }}
                initialValues={(credentials) as ISocialCredentials}
                enableReinitialize
            >{({values, setFieldValue}) => (<>
                <Form>
                    {Object.keys(credentials).map((key) => (
                        <div key={key} className={'mb-2'}>
                            <h4 className="text-left text-uppercase mb-2">{key.replace('_', ' ')}</h4>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor={key + '.login'}>Login</label>
                                        <Field id={key + '.login'} name={key + '.login'} className="form-control"/>
                                        <FormErrorMessage name={key + '.login'}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor={key + '.password'}>Heslo</label>
                                        <div className="input-group show-psw-input-group">
                                            <Field id={key + '.password'}
                                                //@ts-ignore
                                                   type={values[key].pswShown ? 'text' : 'password'}
                                                   name={key + '.password'}
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <button
                                                    //@ts-ignore
                                                    onClick={(e: any) => setFieldValue(`${key}.pswShown`, !values[key].pswShown)}
                                                    className=""
                                                    type="button"><KeyIconSvg/></button>
                                            </div>
                                        </div>
                                        <FormErrorMessage name={key + '.password'}/>
                                    </div>
                                    {/*<div className="input-group show-psw-input-group">*/}
                                    {/*    <Field id="password" type={passwordShown ? 'text' : 'password'} name="password"*/}
                                    {/*           className="form-control"/>*/}
                                    {/*    <div className="input-group-append">*/}
                                    {/*    <button onClick={(e: any) => setPasswordShown(!passwordShown)} className=""*/}
                                    {/*                type="button"*/}
                                    {/*                title={passwordShown ? 'Skryť heslo' : 'Zobraziť heslo'}/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button
                                            disabled={!(values[key as keyof ISocialCredentials]?.login && values[key as keyof ISocialCredentials]?.password)}
                                            onClick={async () => {
                                                await saveCredentials(values, key as unknown as SocialNetwork)
                                                toast.success('Prístupové údaje boli uložené')
                                            }}
                                            type="button"
                                            className="btn btn-primary btn-amani">
                                            Uložiť
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    {[SocialNetwork.FACEBOOK, SocialNetwork.LINKED_IN, SocialNetwork.INSTAGRAM].includes(key as SocialNetwork) &&
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <button
                                                disabled={!(values[key as keyof ISocialCredentials]?.login && values[key as keyof ISocialCredentials]?.password)}
                                                onClick={async () => {
                                                    await saveCredentials(values, key as unknown as SocialNetwork)
                                                    connectToSocialNetwork(key)
                                                }}
                                                type="button"
                                                className="btn btn-danger btn-amani">
                                                Prepojiť účet
                                            </button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    ))}

                    {/*{errorMsg && <div className="text-danger form-text text-center mb-2">{errorMsg}</div>}*/}
                    {/*{successMsg &&*/}
                    {/*    <div className="text-success form-text text-center mb-2">{successMsg}</div>}*/}


                </Form>
            </>)}

            </Formik>
        </ClientFormWrapper>
    )
}
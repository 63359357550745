import React, {useState} from 'react'

import {Field, Form, Formik} from 'formik'
import {Container} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {Link, useLocation, useNavigate} from 'react-router-dom'

import {useAuth} from '../common/Auth'
import FormErrorMessage from '../common/FormErrorMessage'
import NiceButton from '../common/NiceButton'
import PublicLayout from '../common/PublicLayout'

// import FacebookLogin from 'react-facebook-login';


function LoginPage() {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()
    const auth = useAuth()
    const [passwordShown, setPasswordShown] = useState(false)
    const [errorMsg, setErrorMsg] = useState<string>('')
    //@ts-ignore
    const from = location.state?.from?.pathname || '/app/dashboard'

    const validate = (values: any) => {
        const errors: any = {}

        if (!values.email.trim()) {
            errors.email = t('required_field_error')
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Nesprávny formát e-mailovej adresy'
        }

        if (!values.password.trim()) {
            errors.password = t('required_field_error')
        }

        return errors
    }

    const submit = async (values: any) => {
        setErrorMsg('')
        setIsLoading(true)
        try {
            await auth.login(values.email, values.password)
            localStorage.removeItem('startDate')
            localStorage.removeItem('calendar-client')
            navigate(from, {replace: true})
        } catch (error: any) {
            setErrorMsg([401, 403].includes(error?.response.status) ? 'Zadali ste nesprávne prihlasovacie údaje' : error?.response?.data?.message || 'Something went wrong')
        } finally {
            setIsLoading(false)
        }
    }

    const googleLogin = (response: any) => {
        (async () => {
            try {
                await auth.verifyGoogleLogin(response)
                navigate(from, {replace: true})
            } catch (error: any) {
                setErrorMsg(error?.response?.data?.message || 'Something went wrong')
            }
        })()
    }

    const googleLoginFail = (response: any) => {
        console.log('FAILED LOGIN google response', response)
    }

    const facebookLogin = (response: any) => {
        console.log('fb resp', response);
        (async () => {
            try {
                await auth.verifyFacebookLogin(response)
                navigate(from, {replace: true})
            } catch (error: any) {
                setErrorMsg(error?.response?.data?.message || 'Something went wrong')
            }
        })()
    }

    return (
        <PublicLayout>
            <Container>
                <div className="row d-flex justify-content-center py-5">
                    <div className="col-md-5">
                        <h2 className="text-center mb-5">Login</h2>

                        <div className="rounded-content-body">
                            <Formik
                                initialValues={{
                                    email: '', password: ''
                                }}
                                validate={validate}
                                onSubmit={submit}
                            >
                                <Form>
                                    <div className="form-group">
                                        <label htmlFor="email">E-mail</label>
                                        <Field id="email" name="email" className="form-control"/>
                                        <FormErrorMessage name="email"/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="password">Heslo</label>
                                        <div className="input-group show-psw-input-group">
                                            <Field id="password" type={passwordShown ? 'text' : 'password'}
                                                   name="password"
                                                   className="form-control"/>
                                            <div className="input-group-append">
                                                <button onClick={(e: any) => setPasswordShown(!passwordShown)}
                                                        className=""
                                                        type="button"
                                                        title={passwordShown ? 'Skryť heslo' : 'Zobraziť heslo'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                         fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                                        <path
                                                            d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8m4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5"/>
                                                        <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <FormErrorMessage name="password"/>
                                    </div>
                                    {errorMsg &&
                                        <div className="text-danger form-text text-center pb-2">{errorMsg}</div>}
                                    <NiceButton type="submit" isLoading={isLoading}>
                                        Prihlásiť sa
                                    </NiceButton>

                                    {/*<div className="text-center pt-4">*/}
                                    {/*    <Link to="/forgot-password" className="link-secondary fs-small-1_17">Zabudol si*/}
                                    {/*        heslo?</Link>*/}
                                    {/*</div>*/}

                                    {/*<p className="gray-text text-center pt-5 fs-medium-1_5">*/}
                                    {/*    Nemáš ešte účet?<br/>*/}
                                    {/*    <Link to="/register" className="link-primary">Registruj sa zdarma</Link>*/}
                                    {/*</p>*/}

                                    {/*<div className="text-center mt-5 mb-1">*/}
                                    {/*    <GoogleLogin*/}
                                    {/*        clientId="433916595160-fsq6lrvfban45hrokm06a7j23dtppng7.apps.googleusercontent.com"*/}
                                    {/*        buttonText="Prihlás sa cez Google"*/}
                                    {/*        scope="https://www.googleapis.com/auth/user.birthday.read email profile"*/}
                                    {/*        onSuccess={googleLogin}*/}
                                    {/*        render={renderProps => (*/}
                                    {/*            <StyledGoogleLoginBtn type="button" onClick={renderProps.onClick}>Prihlás sa cez*/}
                                    {/*                Google</StyledGoogleLoginBtn>*/}
                                    {/*        )}*/}
                                    {/*        onFailure={googleLoginFail}*/}
                                    {/*        cookiePolicy={'single_host_origin'}/>*/}
                                    {/*</div>*/}
                                    {/*<div className="text-center mt-4 mb-5">*/}
                                    {/*    <FacebookLogin*/}
                                    {/*        appId="1052051295719899"*/}
                                    {/*        fields="name,email,picture"*/}
                                    {/*        render={(renderProps: any) => (*/}
                                    {/*            <StyledFbLoginBtn type="button" onClick={renderProps.onClick}>Prihlás sa cez*/}
                                    {/*                Facebook</StyledFbLoginBtn>*/}
                                    {/*        )}*/}
                                    {/*        onClick={() => {*/}
                                    {/*        }}*/}
                                    {/*        callback={facebookLogin}/>*/}
                                    {/*</div>*/}

                                </Form>
                            </Formik>
                        </div>
                        <div className="text-center pt-4">
                            <Link to="/forgot-password" className="link-secondary fs-small-1_17">Zabudol si
                                heslo?</Link>
                        </div>

                        <p className="gray-text text-center pt-5 fs-medium-1_5">
                            Nemáš ešte účet?<br/>
                            <Link to="/register" className="link-primary">Registruj sa zdarma</Link>
                        </p>

                    </div>
                </div>
            </Container>
        </PublicLayout>
    )
}


export default LoginPage

import React, { FunctionComponent, useEffect, useState } from 'react'

import toast from 'react-hot-toast'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { RequireAuth, useAuth } from './Auth'
import UnauthorizedPage from '../403/403Page'
import AboutUsPage from '../about-us/AboutUsPage'
import AccountActivationPage from '../account-activation/AccountActivationPage'
import AffiliatePage from '../affiliate/AffiliatePage'
import { CalendarAddPage } from '../calendar/CalendarAddPage'
import { CalendarEditPage } from '../calendar/CalendarEditPage'
import CalendarPage from '../calendar/CalendarPage'
import ChangePasswordPage from '../change-password/ChangePasswordPage'
import ClientsAddPage from '../clients/ClientsAddPage'
import ClientsEditPage from '../clients/ClientsEditPage'
import ClientsPage from '../clients/ClientsPage'
import DashboardPage from '../dashboard/DashboardPage'
import ForgotPasswordPage from '../forgot-password/ForgotPasswordPage'
import GoPayCallbackPage from '../gopay-callback/GoPayCallbackPage'
import HomePage from '../home/HomePage'
import ContactPage from '../info-pages/ContactPage'
import FaqPage from '../info-pages/FaqPage'
import GdprPage from '../info-pages/GdprPage'
import { InvoiceAddPage } from '../invoices/InvoiceAddPage'
import { InvoiceEditPage } from '../invoices/InvoiceEditPage'
import InvoicesPage from '../invoices/InvoicesPage'
import LoginPage from '../login/LoginPage'
import { PostsAddPage } from '../posts/PostsAddPage'
import { PostsEditPage } from '../posts/PostsEditPage'
import PostsPage from '../posts/PostsPage'
import UnsubscribePage from '../profile/UnsubscribePage'
import UpdateProfilePage from '../profile/UpdateProfilePage'
import RegisterPage from '../register/RegisterPage'
import ResetPasswordPage from '../reset-password/ResetPasswordPage'
import axiosClient from '../service/axiosClient'
import emitter from '../service/emitter'
import TimesheetAddPage from '../timesheet/TimesheetAddPage'
import TimesheetEditPage from '../timesheet/TimesheetEditPage'
import TimesheetPage from '../timesheet/TimesheetPage'

function Loader() {
    return <div className={'loading-page-warpper'}>
        <div className="loader"></div>
    </div>
}

const AppRouter: FunctionComponent = (props) => {
    const location = useLocation()
    const auth = useAuth()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        const onShowErr = () => {
            navigate('/403')
        }
        emitter.on('show-403-page', onShowErr)

        return () => {
            emitter.off('show-403-page', onShowErr)
        }
    }, [])

    useEffect(() => {
        if (location.pathname.startsWith('/app')) {
            setLoading(true)
            const timeout = setTimeout(() => setLoading(false), 300)
            console.log('location', location)
            // return () => clearTimeout(timeout)
        }
    }, [location])

    useEffect(() => {
        if (auth.user && auth.user.id) {
            axiosClient.get('/dev/get-missing-credentials').then(response => {
                console.log('get-missing-credentials', response.data)
                if (Object.keys(response.data).length > 0) {
                    Object.entries(response.data).forEach(async ([clientId, socialNetworks]) => {
                        const socialNetworksList = (socialNetworks as string[]).map(network => network.replace('_', ' ').toUpperCase()).join(', ')
                        const clientCall = await axiosClient.get(`/client/${clientId}`)
                        const client = clientCall.data
                        const clientName = client.firstName && client.lastName ? client.firstName + ' ' + client.lastName : client.companyName
                        toast.error(`Klient ${clientName} nemá spárovanie s týmito sociálnymi sieťami: ${socialNetworksList}`)
                    })


                }
            }).catch(error => {
                console.log('error', error)
            })
        }
    }, [auth.user])

    return (
        <>{loading && <Loader/>}
            <Routes>

                <Route path="/" element={<HomePage/>}/>
                <Route path="/faq" element={<FaqPage/>}/>
                <Route path="/gdpr" element={<GdprPage/>}/>
                <Route path="/contact" element={<ContactPage/>}/>
                <Route path="/about-us" element={<AboutUsPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
                <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                <Route path="/account-activation" element={<AccountActivationPage/>}/>
                <Route path="/register" element={<RegisterPage/>}/>
                <Route path="/gopay-callback" element={<GoPayCallbackPage/>}/>
                <Route path="/403" element={<UnauthorizedPage/>}/>

                <Route path="/app" element={<RequireAuth/>}>
                    <Route path="edit-profile" element={
                        <UpdateProfilePage/>
                    }/>
                    <Route path="change-password" element={
                        <ChangePasswordPage/>
                    }/>
                    <Route path="dashboard" element={
                        <DashboardPage/>
                    }/>
                    <Route path="affiliate" element={
                        <AffiliatePage/>
                    }/>
                    <Route path="calendar" element={
                        <CalendarPage/>
                    }/>
                    <Route path="calendar/add" element={
                        <CalendarAddPage/>
                    }/>
                    <Route path="calendar/edit/:id" element={
                        <CalendarEditPage/>
                    }/>
                    <Route path="clients/add" element={
                        <ClientsAddPage/>
                    }/>
                    <Route path="clients/:clientId" element={
                        <ClientsEditPage/>
                    }/>
                    <Route path="clients" element={
                        <ClientsPage/>
                    }/>
                    <Route path="timesheet" element={
                        <TimesheetPage/>
                    }/>
                    <Route path="timesheet/add" element={
                        <TimesheetAddPage/>
                    }/>
                    <Route path="timesheet/edit/:entryId" element={
                        <TimesheetEditPage/>
                    }/>
                    <Route path="posts" element={
                        <PostsPage/>
                    }/>
                    <Route path="posts/add" element={
                        <PostsAddPage/>
                    }/>
                    <Route path="posts/edit/:postId" element={
                        <PostsEditPage/>
                    }/>

                    <Route path="invoices" element={
                        <InvoicesPage/>
                    }/>
                    <Route path="invoices/add" element={
                        <InvoiceAddPage/>
                    }/>
                    <Route path="invoices/edit/:invoiceId" element={
                        <InvoiceEditPage/>
                    }/>

                </Route>


                <Route path="/unsubscribe" element={
                    <RequireAuth><UnsubscribePage/></RequireAuth>
                }/>
                <Route path="*" element={<>404</>}/>
            </Routes>
        </>
    )
}

export default AppRouter
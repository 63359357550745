import React, { useState } from 'react'

import moment from 'moment/moment'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'

import { TsListFilter } from './TsListFilter'
import { ClientColorSquare } from '../common/ClientColorSquare'
import { getDuration, money } from '../common/helpers'
import { SmartTable } from '../common/table/Table'
import { SmartTableContext } from '../common/table/TableContext'
import { SmartTableHeadCell } from '../common/table/TableHeadCell'
import { SmartTableWrapper } from '../common/table/TableWrapper'
import axiosClient from '../service/axiosClient'
import { PageableResponse, PagingRequest } from '../types/Common'
import TSEntryDTO, { TimesheetFilter } from '../types/TSEntry'

export const TSList = () => {
    const navigate = useNavigate()
    const [totals, setTotals] = useState({
        totalDurationForCurrentPeriod: '',
        totalForCurrentPeriod: 0
    })

    const [filterInternal, setFilterInternal] = useState<TimesheetFilter>({
        clientId: '',
        from: null,
        until: null,
    })

    function loadDataFn(queryParams: PagingRequest) {
        console.log('queryParams', queryParams)
        const pageNumber = queryParams.pageStart || 0
        const pageSize = queryParams.pageSize || 15
        return new Promise<PageableResponse<TSEntryDTO>>(
            (resolve) => {
                axiosClient.get('timesheet', {
                    params: {
                        pageSize: pageSize,
                        pageStart: pageNumber,
                        clientId: filterInternal.clientId,
                        from: filterInternal.from ? moment(filterInternal.from).format('yyyy-MM-DD') : undefined,
                        until: filterInternal.until ? moment(filterInternal.until).format('yyyy-MM-DD') : undefined
                    }
                }).then(response => {
                    const totalElements = response.data.timesheetEntries.totalElements
                    const paginatedData = response.data.timesheetEntries.content
                    setTotals({
                        totalDurationForCurrentPeriod: response.data.totalDurationForCurrentPeriod,
                        totalForCurrentPeriod: response.data.totalForCurrentPeriod
                    })
                    return resolve({
                        hasNextPage: true,
                        totalElements: totalElements,
                        content: paginatedData,
                    })
                }).catch(error => {
                    console.log('error', error)
                })
            }
        )
    }

    async function deleteItem(id: number, reload: () => void) {
        if (confirm('Naozaj si želáte daný záznam vymazať?')) {
            await axiosClient.delete('timesheet/' + id)
            toast.success('Záznam bol úspešne vymazaný')
            reload()
        }
    }

    return <>
        <TsListFilter filter={filterInternal} setFilter={setFilterInternal} totals={totals}/>
        {/*<div className="rounded-content-body mb-4">*/}
        <SmartTableWrapper<TSEntryDTO, TimesheetFilter>
            initialPageSize={10}
            filter={filterInternal}
            loadDataFn={loadDataFn}>
            <SmartTableContext.Consumer>
                {api => (
                    <SmartTable<TSEntryDTO>
                        onRowClick={(item) => {
                            // navigate('/timesheet/edit/' + item.id)
                        }}
                        HeadRow={
                            <>
                                <SmartTableHeadCell>
                                    Klient
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Popis
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Dátum a čas
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Trvanie
                                </SmartTableHeadCell>
                                <SmartTableHeadCell>
                                    Zárobok
                                </SmartTableHeadCell>
                                <SmartTableHeadCell
                                    className="text-end">
                                    Akcia
                                </SmartTableHeadCell>
                            </>
                        }
                        renderRow={(item) => {
                            return (
                                <>
                                    <td><ClientColorSquare
                                        clientId={item.clientId}/>{`${item.clientFirstName} ${item.clientLastName}`}
                                    </td>
                                    <td>{item.description}</td>
                                    <td>{new Date(item.entryDate).toLocaleDateString('sk-SK')} {item.startedAt.slice(0, 5)} - {item.endedAt.slice(0, 5)}</td>
                                    <td>{item.duration ? `${getDuration(item.duration).hours ? getDuration(item.duration).hours : 0}h ${getDuration(item.duration).minutes ? getDuration(item.duration).minutes : 0}m` : '-'}</td>
                                    <td>{money.format(item.earning ? item.earning : 0)}</td>
                                    <td className="text-right">
                                        <div className="d-flex gap-2 justify-content-end">
                                            <Link to={'/app/timesheet/edit/' + item.id} className={'table-action-link'}
                                                  title={'Upraviť'}>
                                                <i className="bi bi-pencil primary-text-color"></i>
                                            </Link>
                                            <a href={'#'} className={'table-action-link'} onClick={(e) => {
                                                e.preventDefault()
                                                item.id && deleteItem(item.id, () => api.load())
                                            }} title={'Vymazať'}>
                                                <i className="bi bi-trash primary-text-color"></i>
                                            </a>
                                        </div>
                                    </td>
                                </>
                            )
                        }}
                    />
                )}
            </SmartTableContext.Consumer>
        </SmartTableWrapper>
        {/*</div>*/}
    </>
}
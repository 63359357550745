import React, { useEffect } from 'react'

import { Container, Nav, Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import styled from 'styled-components'

import { useAuth } from './Auth'
// import logo from '../assets/logo.svg'
import msgIcon from '../assets/msg_icon.svg'
import profileIcon from '../assets/profile_icon.svg'


function Header() {
    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const {t, i18n} = useTranslation()

    useEffect(() => {
        // window.scrollTo(0, 0)
        //
        // const navEl = document.getElementById('basic-navbar-nav')
        // if (navEl) {
        //     navEl.classList.remove('show')
        // }
    }, [location])

    return (
        <>
            <div className={'top-msg'}>
                <Container className={'text-center font-vollkorn text-uppercase text-color-white'}>Vyskúšaj plnú verziu na 14 dní zadarmo</Container>
            </div>


            <StyledNavbar
                // fixed={'top'}
                className={'sticky-top'}
                collapseOnSelect={true}
                bg="light"
                expand="lg">
                <Container>
                    <Navbar.Brand className={'d-lg-none'} as={Link} to="/"><img src={'/l17-add.svg'} style={{ width: '150px' }}/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">

                        <Nav className="unauthenticated-menu">
                            <Nav.Link
                                as={HashLink}
                                to="/#top">{t('Domov')}</Nav.Link>
                            {/*<Nav.Link*/}
                            {/*    as={HashLink}*/}
                            {/*    to="/#cennik">{t('Cenník')}</Nav.Link>*/}
                            <HashLink smooth className={'nav-link'} to={'/#cennik'}>{t('Cenník')}</HashLink>
                            <HashLink smooth className={'nav-link'}
                                      to={'/#affiliate'}>{t('Affiliate')}</HashLink>
                            {/*<Nav.Link*/}
                            {/*    as={HashLink}*/}
                            {/*    smooth*/}
                            {/*    elementId="affiliate"*/}
                            {/*    to="/#affiliate">{t('Affiliate')}</Nav.Link>*/}
                            {/*<Nav.Link*/}
                            {/*    as={Link}*/}
                            {/*    to="/">{t('Akademia')}</Nav.Link>*/}
                            {/*<Nav.Link*/}
                            {/*    as={Link}*/}
                            {/*    {...(location.pathname === '/about-us' ? { active: true } : {})}*/}
                            {/*    to="/about-us">O nás</Nav.Link>*/}
                        </Nav>

                        <Link to={'/'} className={'logo'}><img src={'/l17-add.svg'}/></Link>
                        {/*<Navbar.Brand className="ms-auto" as={Link} to="/"><img src={logo} style={{width: '150px'}}/></Navbar.Brand>*/}

                        <Nav className="ms-auto unauthenticated-menu">
                            {auth.isAuthenticated ? <> <Nav.Link
                                as={Link}
                                to="/app/dashboard">{t('Dashboard')}</Nav.Link></> : <>
                                <a href="https://www.linkedin.com/company/amani-sk/" target="_blank" rel="noreferrer" className={'nav-link'}><i
                                    className="bi bi-linkedin "></i></a>
                                <a href="https://www.facebook.com/amani.tvoja.online.kancelaria" target="_blank"
                                   rel="noreferrer" className={'nav-link'}><i
                                    className="bi bi-facebook "></i></a>
                                <a href="https://www.instagram.com/amani_tvoja_online_kancelaria" target="_blank" rel="noreferrer" className={'nav-link'}><i
                                    className="bi bi-instagram "></i></a>
                                <Nav.Link
                                    as={Link}
                                    className={'ms-3'}
                                    {...(location.pathname === '/register' ? {active: true} : {})}
                                    to="/register">{t('register')}</Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    {...(location.pathname === '/login' ? {active: true} : {})}
                                    to="/login">{t('login')}</Nav.Link>
                            </>}
                            {/*<Nav.Link*/}
                            {/*    as={Link}*/}
                            {/*    {...(location.pathname === '/about-us' ? { active: true } : {})}*/}
                            {/*    to="/about-us">O nás</Nav.Link>*/}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </StyledNavbar>
        </>
    )
}

const StyledNavbar = styled(Navbar)`
    background: #fff !important;
    min-height: 80px;


    .nav-link {
        text-transform: uppercase;
        color: #393E5D;
        font-weight: 500;
    }

    .collapsing,
    .collapse.show {
        .logo {
            display: none;
        }
    }

    .collapse:not(.show) {
        .logo {
            width: 150px;
            position: absolute;
            left: 50%;
            translate: -50%;
        }
    }

    .navbar-brand {
        background: #fff;
        box-shadow: none;
    }

    //.unauthenticated-menu {
    //  .nav-link {
    //    font-size: 16px;
    //    position: relative;
    //    padding-left: 1rem;
    //    padding-right: 1rem;
    //    text-transform: uppercase;
    //
    //    &::after {
    //      color: #A9A9A9;
    //      content: "I";
    //      position: absolute;
    //      right: -2px;
    //      top: 9px;
    //    }
    //
    //    &.active {
    //      &::before {
    //        content: '';
    //        position: absolute;
    //        left: calc(50% - 10px);
    //        bottom: -27px;
    //        width: 0;
    //        height: 0;
    //        border-left: 10px solid transparent;
    //        border-right: 10px solid transparent;
    //        border-bottom: 10px solid #A9A8A9;
    //        clear: both;
    //      }
    //    }
    //
    //    &:last-child {
    //      &::after {
    //        content: "";
    //      }
    //    }
    //  }
    //}

    .authenticated-menu {
        // flex-direction: column !important;

        .icons-menu {
            // padding-top: 5px;
            align-items: center;
            flex-direction: row !important;
            justify-content: space-between;
            display: flex;
            margin-top: -12px;


            a.icon-item {
                margin-right: 20px;
                padding: 0;
                // font-size: 0;
                // width: 38px;
                height: 38px;
                background: none;
                // mask-repeat: no-repeat;
                // mask-position: center;
                position: relative;
                // background: #949494;

                &.active {
                    color: ${props => props.theme.color};
                }

                span.icon {
                    display: block;
                    width: 38px;
                    margin-left: 6px;
                    height: 38px;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    background: #949494;

                    &.profiles {
                        mask-image: url(${profileIcon});
                    }

                    &.msgs {
                        mask-image: url(${msgIcon});
                    }
                }

                &.active {
                    span.icon {
                        background: ${props => props.theme.color};
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        left: 13px;
                        bottom: -33px;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid ${props => props.theme.color};
                        clear: both;
                    }
                }


                span.notif-counter {
                    font-size: 13px;
                    line-height: 14px;
                    position: absolute;
                    background: ${props => props.theme.color};
                    top: -3px;
                    right: -3px;
                    color: #fff;
                    text-align: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    border: 2px solid #A9A9A9;
                }
            }

            @media only screen and (max-width: 991px) {
                margin-top: 0;
                a.icon-item {
                    font-size: 0;
                }
            }
        }

        .my-name-dropdown {
            height: 33px;
            min-width: 140px;

            #basic-nav-dropdown {
                font-size: 1rem;
                background: ${props => props.theme.color};
                border-radius: 15px;
                color: #fff;
                height: 33px;
                padding: 3px 18px;
                text-transform: none;
                z-index: 2;
                text-align: center;
                position: relative;
            }

            .dropdown-menu {
                padding-top: 22px;
                width: 100%;
                min-width: auto;
                background: #393E5E;
                top: 8px;
                z-index: 1;
                border: none;
                border-radius: 15px;
                position: absolute;

                a {
                    color: #fff;
                    text-align: center;

                    &:hover {
                        background: initial;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .navbar-brand {
            min-height: initial;
            line-height: initial;
        }

        .unauthenticated-menu {
            .nav-link {
                text-align: right;

                &::after {
                    content: '';
                }

                &.active {
                    &::before {
                        content: '';
                        display: none;
                    }
                }
            }
        }

        .my-name-dropdown {
            margin-top: 10px;
        }
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }
`

export default Header

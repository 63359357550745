import React, { useEffect, useState } from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router-dom'

import hands from '../assets/amani-thumb_up.png'
import { useContentWrapper } from '../common/ContentWrapperProvider'
import PublicLayout from '../common/PublicLayout'

const PAID_STATUS = 'PAID'
const CANCELED_STATUS = 'CANCELED'
const TIMEOUTED_STATUS = 'TIMEOUTED'

function UnauthorizedPage() {
    const contentWrapper = useContentWrapper()
    const [searchParams,] = useSearchParams()
    const paymentIdFromUrl = searchParams.get('id')
    const [status, setStatus] = useState('')
    const [showPayAgainBtn, setShowPayAgainBtn] = useState(false)

    useEffect(() => {

    }, [])

    useEffect(() => {
        contentWrapper.setNoBootstrapColumns(true)

        return () => {
            contentWrapper.setNoBootstrapColumns(false)
        }
    }, [])

    return (
        <PublicLayout>
            <div className="info-page">
                <Container>
                    <div className="rounded-content-body mt-3">
                        <Row>
                            <Col>
                                <h2 className="text-center mb-5 mt-4">Zdá sa, že nemáte dostatočné oprávnenia...</h2>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </PublicLayout>
    )
}


export default UnauthorizedPage

import React, { useEffect, useState } from 'react'

import DatePicker from 'react-datepicker'

import { fetchClients } from '../common/helpers'
import axiosClient from '../service/axiosClient'
import ClientDTO from '../types/Client'
import { PostsFilter } from '../types/Post'
import { TimesheetFilter } from '../types/TSEntry'

interface Props {
    filter: PostsFilter
    setFilter: React.Dispatch<React.SetStateAction<PostsFilter>>
}

export const PostsListFilter = ({filter, setFilter}: Props) => {
    const [clients, setClients] = useState<ClientDTO[]>([])

    useEffect(() => {
        fetchClients().then(setClients).catch(console.error)
    }, [])

    return (
        <>
            <div className="rounded-content-body mb-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4">
                        <div
                            className={'form-group'}>
                            <label htmlFor="clientId">
                                Klient
                            </label>
                            <select
                                id="clientId"
                                name="clientId"
                                className="form-select"
                                onChange={(e) => {
                                    setFilter((old) => ({...old, clientId: e.target.value}))
                                }}>
                                <option value="">Vyberte</option>
                                {clients.map((option) => (
                                    <option key={option.id}
                                            value={option.id}>{option.companyName || (`${option.firstName} ${option.lastName}`)}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                    </div>

                </div>
            </div>
        </>
    )
}
import React, { useEffect, useState } from 'react'

import { Table } from 'react-bootstrap'

import { money } from '../common/helpers'
import { NoRecords } from '../common/NoRecords'
import axiosClient from '../service/axiosClient'
import { Package } from '../types/gopay'


type GoPayInvoice = {
    'id': number,
    'invoiceName': string,
    'selectedPackage': Package,
    'status': 'PAID' | 'UNPAID',
    'price': number,
    'parentPaymentCreatedAt': string,
    'parentStatusCreatedAt': string
}

function MyInvoicesList() {
    const [invoices, setInvoices] = useState<GoPayInvoice[]>([])
    useEffect(() => {
        axiosClient.get<GoPayInvoice[]>('/gopay/invoice/info').then(response => {
            setInvoices(response.data)
        }).catch(error => {
            console.log('error', error)
        })

    }, [])

    function downloadInvoice(invoice: GoPayInvoice) {
        axiosClient.get('/gopay/invoice/generate/' + invoice.id).then(response => {
            axiosClient({
                url: '/gopay/invoice/download/' + invoice.invoiceName,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data)

                // create "a" HTML element with href to file & click
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', 'faktura_' + invoice.invoiceName + '.pdf') //or any other extension
                document.body.appendChild(link)
                link.click()

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
            })
        })

    }

    return (
        <><Table className={'fndt_table'} responsive>
            <thead>
            <tr>
                <th scope="col">Číslo FA</th>
                <th scope="col">Balík</th>
                <th scope="col">Stav</th>
                <th scope="col">Suma</th>
                <th scope="col" className={'text-end'}>Akcia</th>
            </tr>
            </thead>
            <tbody>
            {invoices.length ?
                invoices.map((invoice, index) => (
                    <tr
                        className=""
                        key={invoice.id}
                        onClick={() => {
                            // navigate('/clients/' + client.id)
                        }}
                    >
                        {/*<td>{client.id}</td>*/}
                        <td>
                            {invoice.invoiceName}
                        </td>
                        <td>
                            {invoice.selectedPackage}
                        </td>
                        <td>
                            {invoice.status === 'PAID' ? 'Zaplatená' : 'Nezaplatená'}
                        </td>
                        <td>
                            {money.format(invoice.price/100)}
                        </td>
                        <td className="text-right">
                            <div className="d-flex gap-2 justify-content-end">

                                <a href={'#'} className={'table-action-link'} onClick={(e) => {
                                    e.preventDefault()
                                    downloadInvoice(invoice)
                                    // generateAndDownloadInvoicePdfFile(invoice.id.toString(), invoice.invoiceId.toString())
                                }} title={'Stiahnúť PDF'}>
                                    <i className="bi bi-download primary-text-color"></i>
                                    {/*<button className="btn amani-primary amani-small">Upraviť</button>*/}
                                </a>
                            </div>
                        </td>
                    </tr>
                )) : <tr>
                    <td colSpan={5}><NoRecords/></td>
                </tr>}
            </tbody>
        </Table></>

    )
}

export default MyInvoicesList

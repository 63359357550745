import React, { useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import { useAuth } from '../common/Auth'
import { ClientFormWrapper } from '../common/styledComponents'
import axiosClient from '../service/axiosClient'
import { AffiliatePayoutData, PayoutType } from '../types/Payout'


const API_PATHS = { 
    post: `${process.env.REACT_APP_SERVER_URL}/affiliate`,      // POST request to send payout request
    get: `${process.env.REACT_APP_SERVER_URL}/affiliate/info`,  // The original API endpoint for fetching data, 

}

interface AffiliatePayoutProps {
    setActiveKey: (key: string) => void
    setCommissionLettersFilter: (filter: 'all' | 'resolved' | 'unresolved') => void
    triggerRefetch: () => void
}

const AffiliatePayout: React.FC<AffiliatePayoutProps> = ({ setActiveKey, setCommissionLettersFilter, triggerRefetch }) => {
    const {user} = useAuth() // Get user
    const [data, setData] = useState<AffiliatePayoutData>({
        remainingCredit: 0,
        iban: '',
        resolved: 0,   // number of resolved payouts
        unresolved: 0, // number of unresolved payouts
    })
    // ? I am not sure if the number of resolved and unresolved payouts will be received in one request or in separate requests

    const [payoutRequest] = useState({ 
        payoutTypeId: 1,
        credit: 0
    })

    const [unresolvedCL, setUnresolvedCL] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false) // Add loading state

    /** On first render or when user.id is changed fetch data for payouts */
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!API_PATHS.get) {
                    throw new Error('API endpoint for payout is not defined')
                }
                // * Fetch data for payouts [remainingCredit, iban, resolved, unresolved]
                const response = await axiosClient.get<AffiliatePayoutData>(API_PATHS.get)
                setData(response.data as AffiliatePayoutData)
                setUnresolvedCL(response.data.unresolved)


            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [user?.id])


    const handlePayoutRequest = async () => {
        setLoading(true) // Set loading to true when request starts
        try {
            if (!API_PATHS.post) {
                throw new Error('API endpoint for payout is not defined')
            }
            
            // Set the credit to the remainingCredit
            const updatedPayoutRequest = {  // ? Might delete this and use payoutRequest directly
                ...payoutRequest,
                credit: data.remainingCredit //! This should be the remaining credit, set credit to 30 for testing ONLY!!!
            }
      
            // * The invoice is generated and saved on the server
            const response = await axiosClient.post<PayoutType>(API_PATHS.post, updatedPayoutRequest)

            // update data with the new remaining credit after the payout
            setData({
                ...data,
                remainingCredit: data.remainingCredit - updatedPayoutRequest.credit, // ! This will be set to 0 after testing
            })

            setUnresolvedCL(unresolvedCL + 1) // Increment the number of unresolved payouts 
            triggerRefetch()
            toast.success('Faktúra bola úspešne vygenerovaná a uložená na serveri')

        
        } catch (error) {
            const axiosError = error as AxiosError
            console.error('Error sending payout request:', axiosError.response?.data)
            toast.error(axiosError.response?.data ?? 'Nepodarilo sa poslať žiadosť o vyplatenie provízie')
        } finally {
            setLoading(false) // Set loading to false when request completes
        }
    }


  return (
    <ClientFormWrapper>
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="d-flex flex-column align-items-center text-center">
                        <strong className="py-3">Moje aktuálne provízie</strong>
                        <div className="d-flex flex-column align-items-center p-3 border border-2 rounded" 
                        style={{backgroundColor:'#ffecc4', width:'150px', minHeight:'75px'}}>
                            <span style={{fontSize:'0.7rem'}}>Stav prasiatka</span>
                            <span><strong>{ data.remainingCredit }€</strong></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row py-3">
                <div className="col">
                    <div className="d-flex flex-column align-items-center text-center">
                        <strong className="py-3">Nevybavené žiadosti o vyplatenie provízie</strong>
                        <div className="d-flex flex-column align-items-center text-center p-3 border border-2 rounded user-select-none" role="button" 
                        style={{backgroundColor:'#ffecc4', width:'150px', minHeight:'75px'}}
                        onClick={()=>{
                            setCommissionLettersFilter('unresolved')
                            setActiveKey('provizne')
                        }}>
                            {
                                data.unresolved == 0 ?
                                <span style={{fontSize:'0.7rem'}}>Nemáš žiadne žiadosti</span>:
                                <span >{unresolvedCL}</span>
                            }
                            {/* <span style={{fontSize:'0.7rem'}}>nemáš žiadne žiadosti</span> */}
                        </div>
                    </div>
                </div>
                <div className="col">
                <div className="d-flex flex-column align-items-center text-center">
                        <strong className="py-3">Vybavené žiadosti o vyplatenie provízie</strong>
                        <div className="d-flex flex-column align-items-center text-center p-3 border border-2 rounded user-select-none" role="button"
                        style={{backgroundColor:'#ffecc4', width:'150px', minHeight:'75px'}}
                        onClick={()=>{
                            setCommissionLettersFilter('resolved')
                            setActiveKey('provizne')
                        }}>
                            {
                                data.resolved == 0 ? 
                                <span style={{fontSize:'0.7rem'}}>Nemáš žiadne žiadosti</span>:
                                <span >{data.resolved}</span>
                            }
                            {/* <span style={{fontSize:'0.7rem'}}>nemáš žiadne žiadosti</span> */}

                        </div>
                    </div>
                </div>
            </div>                            
            <div className="row">
                    <div className="col">
                        <div className="d-flex flex-column align-items-center text-center">
                            <strong className="py-3">Chcem si vybrať moju províziu na účet</strong>
                            <div
                                className="d-flex flex-column align-items-center py-3 border border-2 rounded user-select-none"
                                role="button"
                                onClick={data.iban && !loading ? handlePayoutRequest : () => {}}  // If there is an IBAN and not loading, handle payout request
                                style={{ backgroundColor: '#ffecc4', minWidth: '150px', minHeight: '75px', cursor: loading ? 'not-allowed' : 'pointer' }}
                            >
                                {data.iban ? (
                                    <>
                                        <span>IBAN: {data.iban}</span>
                                        <span>
                                            <strong>Vybrať</strong>
                                        </span>
                                    </>
                                ) : (
                                    <Link to={'/app/edit-profile'} className="not-uppercase not-bold text-decoration-none" style={{ color: 'inherit' }}>
                                        <span>Vyplniť IBAN</span>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </ClientFormWrapper>
  )
}

export default AffiliatePayout
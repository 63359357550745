import React, { useEffect, useRef, useState } from 'react'

import { FormControl, FormSelect } from 'react-bootstrap'
import { useLocalStorage, useOnClickOutside } from 'usehooks-ts'

import { useAuth } from '../common/Auth'
import { fetchClients } from '../common/helpers'
import axiosClient from '../service/axiosClient'
import ClientDTO from '../types/Client'
import TSEntry from '../types/TSEntry'

export const timeConverter = (time: number): string => {
    return `${('0' + Math.floor((time / 3600000) % 60)).slice(-2)}:${(
        '0' + Math.floor((time / 60000) % 60)
    ).slice(-2)}:${('0' + Math.floor((time / 1000) % 60)).slice(-2)}`
}

const isAlwaysVisible = true

function TimeTrackingWidget() {
    const {user} = useAuth()
    const [timerOn, setTimerOn] = useLocalStorage<boolean>(user?.id + '_timerOn', false)

    // const [timerOn, setTimerOn] = useState(false)
    // const [clients, setClients] = useState<ClientDTO[]>([])
    const [clients, setClients] = useLocalStorage<ClientDTO[]>(user?.id + '_clients', [])

    const [task, setTask] = useLocalStorage(user?.id + '_task', {
        time: 0,
        clientId: '',
        description: '',
        dateStart: '',
    })
    // const [task, setTask] = useState({
    //     time: 0,
    //     clientId: '',
    //     description: '',
    //     dateStart: 0,
    // })

    const [isWidgetVisible, setIsWidgetVisible] = useState(isAlwaysVisible ? true : false)

    const ref = useRef(null)

    const handleClickOutside = () => {
        if (!isAlwaysVisible) {
            setIsWidgetVisible(false)
        }
    }

    useOnClickOutside(ref, handleClickOutside)


    const toggleWidget = () => {
        setIsWidgetVisible(!isWidgetVisible)
    }

    useEffect(() => {
        fetchClients().then(setClients).catch(console.error)
    }, [])

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null

        if (timerOn) {
            interval = setInterval(() => {
                setTask((prevTask) => {
                    const currentTime = new Date().getTime()
                    const timeDiff = currentTime - new Date(prevTask.dateStart).getTime()
                    console.log('timeDiff', timeDiff)
                    return {
                        // time: prevTask.time + 1000,
                        time: timeDiff,
                        clientId: task.clientId,
                        description: task.description,
                        dateStart: task.dateStart,
                    }
                })
            }, 1000)
        } else if (!timerOn) {
            // @ts-ignore
            clearInterval(interval)
        }

        return () => clearInterval(interval as NodeJS.Timeout)
    }, [timerOn, task.description, task.clientId, task.dateStart])

    const onDescriptionChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setTask({
            time: task.time,
            clientId: task.clientId,
            description: e.target.value,
            dateStart: task.dateStart,
        })
    }
    const onClientChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setTask({
            time: task.time,
            description: task.description,
            clientId: e.target.value,
            dateStart: task.dateStart,
        })
    }

    const startHandler = () => {
        setTimerOn(true)
        setTask({
            time: task.time,
            clientId: task.clientId,
            description: task.description,
            dateStart: new Date().toString(),
        })
    }

    const stopHandler = async () => {
        if (confirm('Naozaj chcete zastaviť a uložiť prácu?')) {
            const start = new Date(task.dateStart)
            const end = new Date()
            // convert start to mysql date format YYYY-MM-DD
            const values = {
                entryDate: start.getFullYear() + '-' + ('0' + (start.getMonth() + 1)).slice(-2) + '-' + ('0' + start.getDate()).slice(-2),
                startedAt: (start.getHours().toString().padStart(2, '0') + ':' + start.getMinutes().toString().padStart(2, '0') + ':' + start.getSeconds().toString().padStart(2, '0')),
                endedAt: (end.getHours().toString().padStart(2, '0') + ':' + end.getMinutes().toString().padStart(2, '0') + ':' + end.getSeconds().toString().padStart(2, '0')),
            }

            const request: Omit<TSEntry, 'id' | 'earning'> & { id?: number } = {
                description: task.description,
                clientId: task.clientId as unknown as number,
                entryDate: values.entryDate as unknown as string,
                startedAt: values.startedAt,
                endedAt: values.endedAt,
            }

            await axiosClient.post('timesheet', request)
            setTimerOn(false)
            setTask({
                time: 0,
                description: '',
                clientId: '',
                dateStart: task.dateStart,
            })
            console.log('pridavame novy task', task)
        }
    }

    return <>
        {!isWidgetVisible ?
            <div className={'timetracking-toggle-btn'} onClick={toggleWidget}>
                <i className={`bi ${timerOn ? 'bi-stop-btn blinking' : 'bi-play-btn'}`}></i>
            </div> :
            <div className="timer-widget-wrapper" ref={ref}>

                <FormControl
                    type="text"
                    placeholder="Napíšte činnosť"
                    value={task.description}
                    onChange={onDescriptionChangeHandler}
                />
                <FormSelect
                    value={task.clientId}
                    onChange={onClientChangeHandler}
                >  {timerOn ? null : <option value="">- Klient -</option>}
                    {clients.map((option) => (
                        <option key={option.id}
                                value={option.id}>{option.companyName || (`${option.firstName} ${option.lastName}`)}</option>))}
                </FormSelect>

                <div className=" text-center">{timeConverter(task.time)}</div>
                <div className="">
                    {!timerOn && (
                        <button onClick={startHandler} className="" disabled={!task.description || !task.clientId}>
                            <i className="bi bi-play-btn"></i>
                        </button>
                    )}
                    {timerOn && (
                        <button className="btn-danger" onClick={stopHandler}>
                            <i className="bi bi-stop-btn"></i>
                        </button>
                    )}
                </div>
            </div>
        }
    </>
}

export default TimeTrackingWidget
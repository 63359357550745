import React, { useState } from 'react'

import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import checkIcon from '../assets/check.png'
import listy from '../assets/listy.svg'
import malykvet from '../assets/malykvet.svg'
import velkykvet from '../assets/velkykvet.svg'
import { Package, PaymentPeriod } from '../types/gopay'

export const PriceTable = ({
                               onPackageSelect,
                               hideFreePackage = false,
                               hideTitle = false,
                               defaultPeriod,
                               defaultPackage
                           }:
                               {
                                   onPackageSelect?: (pckg: Package, period: PaymentPeriod) => void,
                                   hideFreePackage?: boolean,
                                   hideTitle?: boolean,
                                   defaultPeriod?: PaymentPeriod,
                                   defaultPackage?: Package
                               }
) => {
    const [paymentPeriod, setPaymentPeriod] = useState<PaymentPeriod>(defaultPeriod ?? PaymentPeriod.MONTHLY)
    return (
        <>
            <Container>
                {!hideTitle ? <Row>
                    <h3 className="font-vollkorn fs-40 primary-text-color mt-5 mb-5">Vyber si svoj balík</h3>
                </Row> : null}
                <div className="d-flex justify-content-center gap-3">
                    <button
                        onClick={() => setPaymentPeriod(PaymentPeriod.YEARLY)}
                        className={`btn btn-payment-period btn-primary  btn-amani w-auto d-inline-block text-uppercase ${paymentPeriod === PaymentPeriod.YEARLY ? 'active' : ''}`}>ročne
                    </button>
                    <button
                        onClick={() => setPaymentPeriod(PaymentPeriod.MONTHLY)}
                        className={`btn btn-payment-period btn-primary btn-amani w-auto d-inline-block text-uppercase ${paymentPeriod === PaymentPeriod.MONTHLY ? 'active' : ''}`}>mesačne
                    </button>
                </div>
                <Row>
                    <StyledWrapper>
                        <div className="content">

                            <div className="basic box">
                                <h2 className="title">Basic</h2>
                                <div className="view">
                                    <div className="icon">
                                        <img src={listy} alt="listy"/>
                                    </div>
                                    <div className="cost">
                                        <p className="amount">€{paymentPeriod === PaymentPeriod.MONTHLY ? '21,99' : '241,99'}</p>
                                        <p className="detail">{paymentPeriod === PaymentPeriod.MONTHLY ? 'mesačne' : 'ročne'} s
                                            DPH
                                            ({paymentPeriod === PaymentPeriod.MONTHLY ? '0,74' : '0,67'}€ na deň)</p>
                                    </div>
                                </div>
                                <p className="desc-text">
                                    Tento program je ideálny práve pre teba, ak práve <strong>začínaš</strong> s
                                    podnikaním a so
                                    správou sociálnych sietí.
                                </p>
                                <div className="description">
                                    <ul>
                                        <li>max. <strong>3</strong> klienti</li>
                                        <li>poznámky
                                        </li>
                                        <li>kalendár</li>
                                        <li>time tracking
                                        </li>
                                        <li>fakturácia
                                        </li>
                                        <li>sociálne siete</li>
                                    </ul>
                                </div>
                                <div className="button">
                                    {onPackageSelect ? <button disabled={defaultPackage === Package.BASIC} className={`text-uppercase ${defaultPackage === Package.BASIC ? 'active' : ''}`}
                                                               onClick={() => onPackageSelect(Package.BASIC, paymentPeriod)}>{defaultPackage === Package.BASIC ? 'aktívny' : 'objednať'}</button> :
                                        <Link to={`/register?package=${Package.BASIC}&period=${paymentPeriod}`}>
                                            <button className="text-uppercase">objednať</button>
                                        </Link>}
                                </div>
                            </div>

                            <div className="standard box">
                                <h2 className="title">Medium</h2>
                                <div className="view">
                                    <div className="icon">
                                        <img src={malykvet} alt="maly kvet"/>
                                    </div>
                                    <div className="cost">
                                        <p className="amount">€{paymentPeriod === PaymentPeriod.MONTHLY ? '24,99' : '264,99'}</p>
                                        <p className="detail">{paymentPeriod === PaymentPeriod.MONTHLY ? 'mesačne' : 'ročne'} s
                                            DPH
                                            ({paymentPeriod === PaymentPeriod.MONTHLY ? '0,84' : '0,75'}€ na deň)</p>
                                    </div>
                                </div>
                                <p className="desc-text">
                                    Ak máš <strong>stabilných klientov</strong> a plánuješ <strong>ďalej rásť</strong>,
                                    tento
                                    balík je pre teba.
                                    S pokročilými funkciami, ako je <strong>fakturácia a emailing</strong>, ušetríš viac
                                    času, a
                                    tak dosiahneš vyššiu efektivitu. S týmto balíkom môžeš spravovať až 7 klientov.
                                </p>
                                <div className="description">
                                    <ul>
                                        <li>max. <strong>7</strong> klientov
                                        </li>
                                        <li>poznámky
                                        </li>
                                        <li>kalendár</li>
                                        <li>time tracking
                                        </li>
                                        <li>fakturácia
                                        </li>
                                        <li>sociálne siete</li>
                                        <li><strong>emailing</strong></li>
                                    </ul>
                                </div>
                                <div className="button">
                                    {onPackageSelect ? <button className={`text-uppercase ${defaultPackage === Package.MEDIUM ? 'active' : ''}`}
                                                               disabled={defaultPackage === Package.MEDIUM}
                                                               onClick={() => onPackageSelect(Package.MEDIUM, paymentPeriod)}>{defaultPackage === Package.MEDIUM ? 'aktívny' : 'objednať'}</button> :
                                        <Link to={`/register?package=${Package.MEDIUM}&period=${paymentPeriod}`}>
                                            <button className="text-uppercase">objednať</button>
                                        </Link>}
                                </div>
                            </div>

                            <div className="business box">
                                <h2 className="title">Premium</h2>
                                <div className="view">
                                    <div className="icon">
                                        <img src={velkykvet} alt="velky kvet"/>
                                    </div>
                                    <div className="cost">
                                        <p className="amount">€{paymentPeriod === PaymentPeriod.MONTHLY ? '29,99' : '285,99'}</p>
                                        <p className="detail">{paymentPeriod === PaymentPeriod.MONTHLY ? 'mesačne' : 'ročne'} s
                                            DPH
                                            ({paymentPeriod === PaymentPeriod.MONTHLY ? '0,99' : '0,78'}€ na deň)</p>
                                    </div>
                                </div>
                                <p className="desc-text">
                                    Si zahltená množstvom úloh a potrebuješ <strong>maximálnu podporu</strong>?
                                    S balíkom Premium budeš mať všetko pod kontrolou!
                                    Získaš priestor pre <strong>neobmedzený počet klientov</strong>, aby si naplno
                                    využila svoj
                                    potenciál. Zahŕňa všetky funkcie stredného balíka <strong>plus úložný
                                    priestor</strong>, čím
                                    sa stáva <strong>komplexným riešením pre náročné podnikateľky</strong>.
                                </p>
                                <div className="description">
                                    <ul>
                                        <li><strong>neobmedzený</strong> počet klientov

                                        </li>
                                        <li>poznámky
                                        </li>
                                        <li>kalendár</li>
                                        <li>time tracking
                                        </li>
                                        <li>fakturácia
                                        </li>
                                        <li>sociálne siete</li>
                                        <li>emailing</li>
                                        <li><strong>úložný priestor</strong></li>
                                    </ul>
                                </div>
                                <div className="button">
                                    {onPackageSelect ? <button disabled={defaultPackage === Package.PREMIUM} className={`text-uppercase ${defaultPackage === Package.PREMIUM ? 'active' : ''}`}
                                                               onClick={() => onPackageSelect(Package.PREMIUM, paymentPeriod)}>{defaultPackage === Package.PREMIUM ? 'aktívny' : 'objednať'}</button> :
                                        <Link to={`/register?package=${Package.PREMIUM}&period=${paymentPeriod}`}>
                                            <button className="text-uppercase">objednať</button>
                                        </Link>}
                                </div>
                            </div>

                        </div>

                        {!hideFreePackage ? <div className={'tip-amani'}>
                            <Link to={`/register?package=${Package.FREE}&period=${paymentPeriod}`}>
                                <button
                                    className="btn btn-primary pink-color btn-amani w-auto d-inline-block text-uppercase">vyskúšaj
                                    premium zadarmo
                                </button>
                            </Link>
                        </div> : null}
                    </StyledWrapper>
                </Row>
            </Container>
        </>
    )
}


const StyledWrapper = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
    //min-height: 100vh;

    //background: #F5F5F4;
    //background: #f0f1f3;

    .content {
        display: flex;
        justify-content: space-between;
        //width: 1200px;
        width: 100%;
        max-width: 1200px;
        margin: 80px;
        margin-bottom: 10px;
    }

    .box {
        display: flex;
        flex-direction: column;
        //height: 586px;
        width: 300px;
        border-radius: 10px;
        margin-left: 10px;
        margin-right: 10px;

        background: #fff;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 10%);
    }

    .title {
        width: 100%;
        padding: 10px 0;
        font-size: 1.2em;
        font-weight: lighter;
        text-align: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        color: #F5F5F4;
    }

    .basic .title {
        background: #9B5B7E;
    }

    .standard .title {
        background: #9098B0;
    }

    .business .title {
        background: #393E5E;
    }

    .view {
        display: block;
        width: 100%;
        padding: 30px 0 20px;

        background: #F5F5F4;
    }

    .icon {
        display: flex;
        justify-content: center;
    }

    .icon img {
        width: 100px;
    }

    .cost {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .amount {
        font-size: 2.8em;
        font-weight: bolder;
        margin-bottom: 0;
    }

    .detail {
        margin: auto 0 auto 5px;
        //width: 70px;
        font-size: 0.7em;
        font-weight: bold;
        line-height: 15px;
        color: #7D7C7C;
    }

    .desc-text {
        margin: 20px 20px 0px;
        font-size: 14px;
        color: #383e60;
        text-align: center;
    }

    .description {
        margin: 30px auto;
        font-size: 14px;
        color: #383e60;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        margin-top: 10px;
    }

    li::before {
        content: "";
        background-image: url(${checkIcon});
        background-position: center;
        background-size: cover;
        opacity: 0.5;

        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    .button {
        margin: 0 auto 30px;
        margin-top: auto;
    }

    button {
        height: 40px;
        width: 250px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: #fff;
        border: none;
        border-radius: 10px;
        background: #393E5E;
    }

    button.active,
    button:hover {
        color: #F5F5F4;
        transition: 0.5s;
        border: none;
        transform: scale(1.03);
        background: #CDA5BD;
    }

    /* Responsiveness:Start */
    @media screen and (max-width: 970px) {
        .content {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 50px auto;
        }

        .standard, .business {
            margin-top: 25px;
        }
    }
    /* Responsiveness:End */
`